import { Avatar, Box } from "@mui/material";
import React from "react";
import { BoxItemMenuMobile, ItemMenuMobile } from "./Navbar.styled";
import { getInitials } from "../../utils/functions";
import { HouseLine, ListBullets, SignOut } from "@phosphor-icons/react";
import { routes } from "../../utils/paths";

export const PopperHasUser = ({
  displayName,
  photoURL,
  handleClose,
  isAdmin,
  userSignOut,
}) => {
  return (
    <Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
      <ItemButtonMenu
        icon={<HouseLine size={36} />}
        text={"Inicio"}
        to={routes.home}
        handleClose={handleClose}
      />
      <ItemButtonMenu
        icon={
          <Avatar src={photoURL || ""}>
            {displayName && getInitials(displayName)}
          </Avatar>
        }
        text={"Mi perfil"}
        to={routes.profile}
        handleClose={handleClose}
      />

      <ItemButtonMenu
        icon={<ListBullets size={36} />}
        text={"Mis QRs"}
        to={routes.myqrs}
        handleClose={handleClose}
      />

      <ItemButtonMenu
        icon={<SignOut size={36} />}
        text={"Cerrar sesión"}
        to={routes.home}
        handleClose={() => {
          userSignOut();
          handleClose();
        }}
      />

      {isAdmin && (
        <ItemButtonMenu
          icon={<></>}
          text={"Administración"}
          to={routes.adminUsers}
          handleClose={handleClose}
        />
      )}
    </Box>
  );
};

export const ItemButtonMenu = ({ icon, text, handleClose, to }) => {
  return (
    <BoxItemMenuMobile>
      <ItemMenuMobile onClick={handleClose} to={to}>
        <Box sx={{ width: 60, textAlign: "left", display: "flex" }}>{icon}</Box>
        <Box> {text}</Box>
      </ItemMenuMobile>
    </BoxItemMenuMobile>
  );
};
