import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import { QrScannerModal } from "../../QrScanner/QrScannerModal";
import { ITEM_WORKING, heightNavbar } from "../../../utils/constants";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { theme } from "../../theme";
import { ButtonCustom } from "../../Common.styled";
import { PapperHome, PapperHomeVersionTwo } from "./Home.styled";
import { Link } from "react-router-dom";
import { routes } from "../../../utils/paths";
import { ItemWorking } from "../Working/ItemWorking";
import { CarouselHome } from "./CarouselHome";

export const Home = () => {
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container
      p={{ xs: 3, sm: 1 }}
      maxWidth={"md"}
      disableGutters={isSmall}
      sx={{ minHeight: `calc(100vh - ${heightNavbar})`, paddingBottom: 3 }}
    >
      {isSmall && <CarouselHome isSmall={isSmall} />}
      <Box
        alignItems={"center"}
        textAlign={"center"}
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <PapperHome>
          {!isSmall && <CarouselHome isSmall={isSmall} />}
          <Typography mt={!isSmall ? 1 : 0} variant="h5" color={"primary"}>
            ¿Encontraste un QR?
          </Typography>
          <QrScannerModal />
        </PapperHome>

        <PapperHomeVersionTwo>
          <LazyLoadImage
            alt={"Imagen de perfil de"}
            effect="blur"
            src={"./images/bannerIdentificador.png"}
            width={"100%"}
            style={{
              objectFit: "cover",
              borderRadius: 30,
              height: !isSmall ? "650px" : "",
            }}
          />
          <Box p={{ xs: 2, sm: 0 }}>
            <Typography mt={1} variant="h7" color={"primary"}>
              Escanea y conecta tus mascotas y objetos al mundo digital.
            </Typography>
            <Box>
              <ItemWorking
                label={"¡Quiero mi QR!"}
                text={ITEM_WORKING.SHOPS.text}
                body={ITEM_WORKING.SHOPS.body}
                modeButton
              />
            </Box>
          </Box>
        </PapperHomeVersionTwo>

        <PapperHomeVersionTwo>
          <Box p={{ xs: 2, sm: 0 }}>
            <LazyLoadImage
              alt={"Imagen de perfil de"}
              effect="blur"
              src={"./images/QuestionPhoto.jpg"}
              width={"100%"}
              style={{
                objectFit: "cover",
                borderRadius: 30,
                height: !isSmall ? "650px" : "",
              }}
            />

            <Box>
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to={routes.working}
              >
                <ButtonCustom invert>Preguntas frecuentes</ButtonCustom>
              </Link>
            </Box>
          </Box>
        </PapperHomeVersionTwo>
        <Box mt={4} width={"100%"}>
          <iframe
            width="100%"
            height="560"
            src="https://www.youtube.com/embed/1u0sYAbe9js"
            title="Eva BuscaQR"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </Box>
        <iframe
          title="Mapa puntos de venta"
          src="https://www.google.com/maps/d/embed?mid=18o_REnAu562EsDmzUuoI88zRDjm8ukM&ehbc=2E312F"
          width="100%"
          height="480"
        />
      </Box>
    </Container>
  );
};
