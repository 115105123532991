import { Container } from "@mui/material";
import { FormLogin } from "./FormLogin";
import { BoxGoogleButton } from "./Login.styled";
import { ButtonGoogleCustom } from "../../ButtonGoogleCustom";
import { ResetPassword } from "../../ResetPassword/ResetPassword";

export const Login = ({ registerClick, onClose }) => {
  return (
    <div>
      <Container maxWidth={"md"} sx={{ maxWidth: "400px !important" }}>
        <FormLogin onClose={onClose} />

        <BoxGoogleButton>
          <ButtonGoogleCustom label="Continuar con Google" onClose={onClose} />
        </BoxGoogleButton>
        <ResetPassword />
      </Container>
    </div>
  );
};
