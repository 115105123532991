import axios from "axios";
import { getHeader } from "../utils/functions";
import { collection, getDocs } from "@firebase/firestore";
import { typeObject } from "../utils/constants";
import { where, query, getCountFromServer } from "firebase/firestore";

export class Pet {
  constructor() {
    this.axios = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}pets`, // URL base de la API
    });
  }

  getPetById(id) {
    return this.axios.get(`/${id}`);
  }
  setPet({ id, body, token }) {
    return this.axios.put(`/edit/${id}`, body, getHeader(token));
  }
  generateQR(token) {
    return this.axios.put(`/create-emtpy-pet`, {}, getHeader(token));
  }
  getAllPets = async (db) => {
    try {
      const querySnapshot = await getDocs(collection(db, typeObject?.pets));
      return querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.error("Error al obtener documentos:", error);
    }
  };
  getCountPetsRegisters = async (db) => {
    try {
      const coll = collection(db, typeObject?.pets);
      const q = query(coll, where("created", "==", true));
      const snapshot = await getCountFromServer(q);
      const count = snapshot.data().count;
      return count;
    } catch (error) {
      console.error("Error al obtener documentos:", error);
    }
  };

  getPetsByUser = async (db, email) => {
    try {
      console.log(email);
      const queryCustom = query(
        collection(db, typeObject?.pets),
        where("email", "==", email)
      );
      const snapshot = await getDocs(queryCustom);
      const pets = snapshot.docs.map((doc) => doc.data());
      return pets;
    } catch (error) {
      console.error("Error al obtener documentos:", error);
    }
  };
  getCountUsersRegisters = async (db) => {
    try {
      const coll = collection(db, typeObject?.user);
      const q = query(coll);
      const snapshot = await getCountFromServer(q);
      const count = snapshot.data().count;
      return count;
    } catch (error) {
      console.error("Error al obtener documentos:", error);
    }
  };
}

export const PetService = new Pet();
