import { collection, getDocs } from "@firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { typeObject } from "../../../utils/constants";
import CurrentUserContext from "../../../contexts/CurrentUserContext";
import { TableUsers } from "./TableUsers";
import { query, where } from "firebase/firestore";
import { useCountUsers } from "../../../hooks/Users/useCountUsers";
import dayjs from "dayjs";
import { ButtonCustom } from "../../Common.styled";

export const UserBo = () => {
  const { db } = useContext(CurrentUserContext);
  const [documentos, setDocumentos] = useState([]);
  const [menu, setMenu] = useState(true);
  const today = [
    dayjs().format("DD/MM/YYYY"),
    dayjs().format("D/M/YYYY"),
    dayjs().format("D/MM/YYYY"),
    dayjs().format("DD/M/YYYY"),
  ];
  const yesterday = [
    dayjs().subtract(1, "day").format("DD/MM/YYYY"),
    dayjs().subtract(1, "day").format("D/M/YYYY"),
    dayjs().subtract(1, "day").format("D/MM/YYYY"),
    dayjs().subtract(1, "day").format("DD/M/YYYY"),
  ];

  const getQuery = () => {
    if (menu === "all") {
      return query(collection(db, typeObject?.user));
    }
    if (menu === "today") {
      return query(
        collection(db, typeObject?.user),
        where("creationDate", "in", today)
      );
    }
    if (menu === "yesterday") {
      return query(
        collection(db, typeObject?.user),
        where("creationDate", "in", yesterday)
      );
    }
  };

  useEffect(() => {
    const obtenerDocumentos = async (menu) => {
      try {
        const queryCustom = getQuery();
        const snapshot = await getDocs(queryCustom);
        const users = snapshot.docs.map((doc) => doc.data());
        setDocumentos(users);
      } catch (error) {
        console.error("Error al obtener documentos:", error);
      }
    };

    obtenerDocumentos(menu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu]);
  const { data } = useCountUsers();
  return (
    <div>
      <h1>Usuarios</h1>
      <h2>Cantidad de usuarios totales: {data}</h2>
      <ButtonCustom onClick={() => setMenu("all")}>Mostrar todos</ButtonCustom>
      <ButtonCustom onClick={() => setMenu("yesterday")}>Ayer</ButtonCustom>
      <ButtonCustom onClick={() => setMenu("today")}>Hoy</ButtonCustom>

      <TableUsers data={documentos} menu={menu} />
    </div>
  );
};
