import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "react-lazy-load-image-component/src/effects/blur.css";

export const CarouselHome = ({ isSmall }) => {
  return (
    <div>
      <Carousel
        showThumbs={false}
        autoPlay={true}
        infiniteLoop={true}
        interval={3000}
      >
        <div>
          <LazyLoadImage
            alt={"Imagen de portada 1"}
            effect="blur"
            src={"./images/BannerFox.jpg"}
            width={"100%"}
            style={{
              objectFit: "cover",
              borderRadius: isSmall ? 0 : 20,
              height: !isSmall ? "650px" : "",
            }}
          />
        </div>
        <div>
          <LazyLoadImage
            alt={"Imagen de portada 2"}
            effect="blur"
            src={"./images/evaBanner.png"}
            width={"100%"}
            style={{
              objectFit: "cover",
              borderRadius: isSmall ? 0 : 20,
              height: !isSmall ? "650px" : "",
            }}
          />
        </div>
      </Carousel>
    </div>
  );
};
