import { Box } from "@mui/material";
import { MenuBO } from "./MenuBO/MenuBO";
import { menuOptionBO } from "../../utils/constants";
import { UserBo } from "./UsersBO/UserBo";
import { PetsBO } from "./PetsBO/PetsBO";
import { ObjetsBO } from "./ObjetsBO.js/ObjectsBO";
import { useParams } from "react-router";
import { StoreBO } from "./StoreBO/StoreBO";
import { useContext } from "react";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import { isUserAdmin } from "../../utils/functions";

export const BO = () => {
  let { type } = useParams();
  const { userData } = useContext(CurrentUserContext);
  const isAdmin = isUserAdmin(userData);

  return isAdmin ? (
    <Box display={"flex"}>
      <MenuBO />
      {type === menuOptionBO?.users && <UserBo />}
      {type === menuOptionBO?.pets && <PetsBO />}
      {type === menuOptionBO?.objects && <ObjetsBO />}
      {type === menuOptionBO?.stores && <StoreBO />}
    </Box>
  ) : (
    <></>
  );
};
