import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { CaretDown, FacebookLogo, InstagramLogo } from "@phosphor-icons/react";
import { Link } from "react-router-dom";
import { theme } from "../../theme";

export const AccordionPerfilRedesSociales = ({ instagram, facebook }) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<CaretDown size={32} />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography fontWeight={700} color={"primary"}>
          Redes sociales
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {instagram && (
          <Link
            to={instagram}
            style={{
              textDecoration: "none",
              color: theme.palette.primary.main,
              display: "flex",
              alignItems: "center",
            }}
          >
            <InstagramLogo size={32} />
            <Typography ml={2}> Ir al perfil</Typography>
          </Link>
        )}
        {facebook && (
          <Link
            to={facebook}
            style={{
              textDecoration: "none",
              color: theme.palette.primary.main,
              display: "flex",
              alignItems: "center",
              marginTop: 10,
            }}
          >
            <FacebookLogo size={32} />
            <Typography ml={2}> Ir al perfil</Typography>
          </Link>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
