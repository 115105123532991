import { Box, Typography } from "@mui/material";
import { ButtonWhatsapp } from "../../ButtonWhatsapp/ButtonWhatsapp";
import { formatPhoneNumber } from "../../../utils/functions";
import { ButtonPet } from "./Pets.styled";
import { User } from "@phosphor-icons/react";

export const ContactActions = ({ number, name, label }) => {
  return (
    <>
      <Typography
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        m={1}
        fontWeight={700}
        fontSize={18}
      >
        <User style={{ marginRight: 10 }} /> {label}
      </Typography>
      <Box display={"flex"} justifyContent={"center"}>
        <ButtonWhatsapp
          text={"Hola! escanee el QR de " + name}
          cel={formatPhoneNumber(number)}
        />
      </Box>
      <Box mt={1} display={"flex"} justifyContent={"center"}>
        <ButtonPet
          onClick={() =>
            window.open(`tel:${formatPhoneNumber(number)}`, "_blank")
          }
        >
          <Typography
            mt={1}
            fontSize={20}
            fontWeight={800}
            style={{ fontFamily: "Light" }}
          >
            Llamar
          </Typography>
        </ButtonPet>
      </Box>
    </>
  );
};
