import styled from "@emotion/styled";
import { Box, Paper } from "@mui/material";
import { theme } from "../../theme";

export const ContainerPhoto = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: { fontSize: "10vw" },
}));
export const ContainerInfo = styled(Box)(() => ({ textAlign: "center" }));

export const PaperHasntProfile = styled(Paper)(() => ({
  textAlign: "center",
  borderRadius: 10,
  padding: 8,
  margin: 16,
}));
