import { Container, Skeleton } from "@mui/material";
import React, { useContext } from "react";
import CurrentUserContext from "../../../../contexts/CurrentUserContext";
import { FormRegisterStore } from "./FormRegisterStore";

export const RegisterStore = () => {
  const { isLoadingUser } = useContext(CurrentUserContext);

  return (
    <Container sx={{ padding: 4 }} maxWidth={"md"}>
      {isLoadingUser ? <SkeletonRegisterPet /> : <FormRegisterStore />}
    </Container>
  );
};

const SkeletonRegisterPet = () => {
  return (
    <>
      <Skeleton width="100%" height={50} />
      <Skeleton width="100%" height={100} />
      <Skeleton width="100%" height={100} />
      <Skeleton width="100%" height={100} />
    </>
  );
};
