import { getAuth, onAuthStateChanged } from "firebase/auth";
import { createContext, useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { typeObject } from "../utils/constants";
import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";

const app = initializeApp(JSON.parse(process.env.REACT_APP_BD));

const auth = getAuth(app);
const db = getFirestore(app);

const CurrentUserContext = createContext({});
export function CurrentUserProvider({ children }) {
  const [userData, setUserData] = useState(null);
  const [isLoadingUser, setIsLoadingUser] = useState(true);

  const logOut = () => {
    auth.signOut().then(() => {
      setUserData(null);
    });
  };

  const updateSetUserData = async () => {
    const docRef = doc(db, typeObject?.user, userData?.uid);
    const docSnap = await getDoc(docRef);
    const data = docSnap?.data();
    if (data) setUserData({ ...userData, ...data });
  };

  const listen = () => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const docRef = doc(db, typeObject?.user, user?.uid);
        const docSnap = await getDoc(docRef);
        const data = docSnap?.data();
        if (data) setUserData({ ...user, ...data });
        setIsLoadingUser(false);
      } else {
        setIsLoadingUser(false);
        setUserData(null);
      }
    });
  };

  useEffect(() => {
    listen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CurrentUserContext.Provider
      value={{
        userData,
        setUserData,
        logOut,
        db,
        app,
        auth,
        isLoadingUser,
        listen,
        updateSetUserData,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
}

export default CurrentUserContext;
