import React from "react";
import { Link } from "react-router-dom";
import { theme } from "./theme";
import { ShoppingCart } from "@phosphor-icons/react";
import { Typography } from "@mui/material";

export const ButtonShopML = () => {
  return (
    <Link
      style={{
        textDecoration: "none",
        color: theme.palette.primary.main,
        paddingLeft: 10,
        display: "flex",
      }}
      target="_blank"
      to={"https://buscaqr.mercadoshops.com.ar"}
    >
      <ShoppingCart size={32} />
      <Typography ml={2} fontWeight={700} alignSelf={"center"}>
        Ir a la tienda Online
      </Typography>
    </Link>
  );
};
