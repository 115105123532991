import React from "react";
import { ItemWorking } from "./ItemWorking";
import { ContainerWorking } from "./Working.styled";
import { ITEM_WORKING } from "../../../utils/constants";
import { Box, IconButton, Typography } from "@mui/material";
import { ArrowCircleLeft } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../utils/paths";

export const Working = () => {
  const navigate = useNavigate();

  return (
    <ContainerWorking>
      <Box>
        <IconButton onClick={() => navigate(routes.home)}>
          <ArrowCircleLeft size={32} />
          <Typography ml={2} fontSize={20}>
            Volver
          </Typography>
        </IconButton>
      </Box>

      <ItemWorking label={"¿Que es?"} text={ITEM_WORKING.QUE_ES} />
      <ItemWorking
        label={"¿Cómo funciona?"}
        text={ITEM_WORKING.COMO_FUNCIONA}
      />
      <ItemWorking
        label={"¿Cómo se cargan los datos en el QR?"}
        text={ITEM_WORKING.COMO_CARGAR_QR.text}
        body={ITEM_WORKING.COMO_CARGAR_QR.body}
      />
      <ItemWorking
        label={"¿Cómo edito una mascota?"}
        body={ITEM_WORKING.EDIT.body}
      />
      <ItemWorking
        label={"¿Qué datos se pueden cargar?"}
        text={ITEM_WORKING.DATA.text}
        body={ITEM_WORKING.DATA.body}
      />
      <ItemWorking
        label={"Quiero el QR para mi mascota. ¿Cómo compro?"}
        text={ITEM_WORKING.SHOPS.text}
        body={ITEM_WORKING.SHOPS.body}
      />
    </ContainerWorking>
  );
};
