import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { theme } from "../theme";

export const ContainerQRScannerCircleIcon = styled(Box)(() => ({
  backgroundColor: theme.palette.primary.main,
  position: "absolute",
  top: -10,
  bottom: "-18px",
  width: 22,
  padding: "5px",
  height: 22,
  zIndex: "1000",
  borderRadius: "50% !important",
  color: theme.palette.primary.secondary,
  border: `1px solid ${theme.palette.primary.secondary}`,
}));

export const ContainerScanner = styled(Box)(() => ({
  position: "fixed",
  backgroundColor: theme.palette.primary.main,
  bottom: "-8px",
  height: "40px",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  borderTop: `1px solid ${theme.palette.primary.main}`,
}));

export const ContainerUser = styled(Box)(() => ({
  position: "absolute",
  backgroundColor: theme.palette.primary.main,
  bottom: 3,
  width: 22,
  left: "12vh",
  padding: "5px",
  zIndex: "1000",
  color: theme.palette.primary.secondary,
}));

export const ContainerList = styled(Box)(() => ({
  position: "absolute",
  backgroundColor: theme.palette.primary.main,
  bottom: 3,
  right: "12vh",
  width: 22,
  padding: "5px",
  zIndex: "1000",
  color: theme.palette.primary.secondary,
}));
