import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { theme } from "../theme";

export const BoxSubtitle = styled(Box)(() => ({
  fontSize: 30,
  fontWeight: 700,
  color: theme.palette.primary.main,
  textAlign: "center",
}));

export const BoxActions = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: 220,
  marginTop: 20,
}));

export const BoxDetail = styled(Box)(() => ({
  fontSize: 20,
  fontWeight: 600,
  color: theme.palette.primary.main,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  textAlign: "center",
  marginTop: 20,
}));
