import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { theme } from "../../../theme";
import { PencilLine } from "@phosphor-icons/react";
import { FormRegisterObject } from "../NewObject/FormRegisterObject";

export const EditObject = ({ data }) => {
  const [open, setOpen] = useState(false);
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box sx={{ position: "absolute", left: 10, bottom: 10 }}>
      <Box>
        <IconButton
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
          onClick={() => setOpen(true)}
        >
          <PencilLine color={theme.palette.primary.secondary} size={32} />
        </IconButton>

        <Dialog
          open={open}
          fullScreen={isSmall}
          fullWidth
          onClose={() => setOpen(false)}
        >
          <DialogContent>
            <FormRegisterObject edit data={data} close={() => setOpen(false)} />
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
};
