import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { X } from "@phosphor-icons/react";
import { FormResetPassword } from "./FormResetPassword";
import { LinkButton } from "../Common.styled";

export const ResetPassword = ({ email }) => {
  const [open, setopen] = useState(false);

  return (
    <Box textAlign={"center"}>
      <LinkButton mb={2} onClick={() => setopen(true)}>
        Olvidé la contraseña
      </LinkButton>
      <Dialog open={open} maxWidth={"md"}>
        <DialogTitle display={"flex"} justifyContent={"space-between"}>
          Olvidé la contraseña
          <IconButton onClick={() => setopen(false)}>
            <X size={20} />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <FormResetPassword />
        </DialogContent>
      </Dialog>
    </Box>
  );
};
