import React, { useContext } from "react";
import CurrentUserContext from "../../../contexts/CurrentUserContext";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { StoresService } from "../../../services/StoresService";
import { routes } from "../../../utils/paths";
import { namesUseQuery } from "../../../utils/constants";
import { ContainerTitle } from "../PetsBO/PetsBO.styled";
import { ButtonLoading } from "../../ButtonLoading";
import { TableStore } from "./TableStore";
import { useState } from "react";
import { ButtonCustom } from "../../Common.styled";
import { ModalViewQr } from "../../ModalViewQr/ModalViewQr";
import { getUrlStoreQr } from "../../../utils/functions";
import { useCountStores } from "../../../hooks/Store/useCountStores";
import { SearchStoreBO } from "./SearchStoreBO";

export const StoreBO = () => {
  let { newQR } = useParams();
  const [viewAll, setviewAll] = useState(false);
  const [openBusqueda, setOpenBusqueda] = useState(false);

  const { userData } = useContext(CurrentUserContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const mutateGenerateQR = async () => {
    const response = await StoresService.generateQR(userData?.accessToken);
    return response?.data?.id;
  };

  const { mutate, isLoading } = useMutation(mutateGenerateQR, {
    onSuccess: (id) => {
      alert("Se genero un QR con el siguiente ID: " + id);
      navigate(routes.adminStores + "/" + id);
      queryClient.invalidateQueries(namesUseQuery?.GET_STORE_BY_ID);
    },
    onError: (err) => {
      console.error(err);
    },
  });
  const { data } = useCountStores();

  return (
    <div>
      <ContainerTitle>
        <h1>Tiendas</h1>
        <ButtonLoading
          isLoading={isLoading}
          onClick={() => mutate(userData?.accessToken)}
          sx={{ height: 40 }}
        >
          Generar Nueva Tienda
        </ButtonLoading>
        {newQR && (
          <ModalViewQr
            paramQRBO={newQR}
            name={newQR}
            position={"relative"}
            url={getUrlStoreQr(newQR)}
            id={newQR}
          />
        )}
      </ContainerTitle>
      <ButtonCustom
        onClick={() => {
          setOpenBusqueda(true);
        }}
      >
        Busqueda
      </ButtonCustom>
      {openBusqueda && (
        <SearchStoreBO
          open={openBusqueda}
          onClose={() => setOpenBusqueda(false)}
        />
      )}
      <h4>Cantidad de tiendas registradas: {data}</h4>
      <ButtonCustom onClick={() => setviewAll(true)}>
        Mostrar todos
      </ButtonCustom>
      {viewAll && <TableStore />}
    </div>
  );
};
