import { Box, Skeleton, Typography } from "@mui/material";
import { redirigirConProtocolo } from "../../utils/functions";
import { QrReader } from "react-qr-reader";
import { useSnackBars } from "../../contexts/Snackbar/SnackbarContext";
import { useEffect, useState } from "react";
import { ButtonCustom } from "../Common.styled";
import { Flashlight } from "@phosphor-icons/react";
import { Link } from "react-router-dom";
import { theme } from "../theme";

export const CameraContainer = ({ seturlRedirect }) => {
  const { showErrorSnackbar } = useSnackBars();
  const [torch, setTorch] = useState(true);
  const [hiddenQrReader, sethiddenQrReader] = useState(false);
  const audio = new Audio("./audios/audioScanner.mp3");

  const redirectToQr = (url) => {
    audio.play();
    seturlRedirect(redirigirConProtocolo(url));
  };

  const handleError = (err) => {
    showErrorSnackbar(err);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      sethiddenQrReader(false);
      console.log("sethiddenQrReader(false);");
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [hiddenQrReader]);

  return (
    <>
      <Box width={"100%"} textAlign={"center"} sx={{ position: "relative" }}>
        <Box
          sx={{
            position: "relative",
            width: "100%",
          }}
        >
          {!hiddenQrReader ? (
            <QrReader
              delay={500}
              onError={handleError}
              ViewFinder={() => {
                return <FocusViewFinder />;
              }}
              onResult={(result, error) => {
                if (!!result) {
                  redirectToQr(result?.text);
                }
                if (!!error) {
                  console.info(error);
                }
              }}
              constraints={{
                facingMode: "environment",
                focusMode: "continuous",
                zoom: 2,
                torch: torch,
                aspectRatio: { ideal: 1 },
              }}
            />
          ) : (
            <Skeleton height={300} />
          )}
        </Box>
        <ButtonCustom
          onClick={() => {
            setTorch((prev) => !prev);
            sethiddenQrReader(true);
          }}
          invert={!torch}
          loading={hiddenQrReader}
        >
          <Flashlight size={32} />
        </ButtonCustom>
        <Box sx={{ margin: theme.spacing(4, 3) }}>
          <Typography>
            Si tiene dificultades para escanear el QR puede utilizar el lector
            de{" "}
            <Link
              to={
                "https://play.google.com/store/apps/details?id=com.google.android.googlequicksearchbox"
              }
            >
              Google
            </Link>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

const FocusViewFinder = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "40vw",
        height: "40vw",
        boxSizing: "border-box",
        pointerEvents: "none", // Para evitar que el marco bloquee la interacción con la cámara
        zIndex: 2,
      }}
    >
      <img style={{ width: "100%" }} src={"./images/zoneScan.png"} alt="" />
    </Box>
  );
};
