import React, { useRef, useState } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { ButtonCustom } from "../Common.styled";
import { useDebounceEffect } from "./useDebounceEffect";
import { canvasPreview } from "./canvasPreview";
import { theme } from "../theme";
import {
  Box,
  ButtonBase,
  Dialog,
  DialogContent,
  useMediaQuery,
} from "@mui/material";
import { MinusCircle, PlusCircle } from "@phosphor-icons/react";
import {
  ContainerActionCrop,
  ContainerActionImageForm,
  ContainerZoom,
} from "./UploadImage.styled";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}
export const UploadImage = ({
  setPreviewImg,
  previewImg,
  setImageStorage,
  edit,
}) => {
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setopen] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const blobUrlRef = useRef("");
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const aspect = 4 / 3;

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgSrc(reader.result?.toString() || "");
        setopen(true);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  async function onDownloadCropClick() {
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error("Crop canvas does not exist");
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    // Definir los anchos deseados
    const anchos = [800, 500, 200];
    const blobs = [];

    for (let i = 0; i < anchos.length; i++) {
      const targetWidth = anchos[i];
      const targetHeight =
        (completedCrop.height * scaleX * targetWidth) /
        (completedCrop.width * scaleY);

      const offscreen = new OffscreenCanvas(targetWidth, targetHeight);
      const ctx = offscreen.getContext("2d");
      if (!ctx) {
        throw new Error("No 2d context");
      }

      ctx.drawImage(
        previewCanvas,
        0,
        0,
        previewCanvas.width,
        previewCanvas.height,
        0,
        0,
        targetWidth,
        targetHeight
      );

      const blob = await offscreen.convertToBlob({
        type: "image/jpeg",
        quality: 0.8,
      });

      blobs.push(blob);

      // Ejemplo: Mostrar la imagen en la página
      const imgElement = document.createElement("img");

      imgElement.src = URL.createObjectURL(blob);
    }

    const reader = new FileReader();
    reader.onload = function () {
      const base64Image = reader.result;
      setPreviewImg(base64Image);
    };
    reader.readAsDataURL(blobs?.[0]);

    // Almacenar el array de blobs en setImageStorage
    setImageStorage(blobs);

    // Limpiar URLs antiguas si es necesario
    if (blobUrlRef.current) {
      blobUrlRef.current.forEach(URL.revokeObjectURL);
    }

    // Crear nuevas URLs para los blobs
    blobUrlRef.current = blobs.map(URL.createObjectURL);

    // Ejemplo: Configurar enlaces de descarga para cada imagen
    for (let i = 0; i < blobs.length; i++) {
      const a = document.createElement("a");
      a.href = blobUrlRef.current[i];
      a.download = `imagen_${anchos[i]}px.jpg`;
    }

    // ... Resto del código ...
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale
        );
      }
    },
    100,
    [completedCrop, scale]
  );

  const styleLabel = {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 20,
    color: theme.palette.primary.secondary,
    fontWeight: 700,
    padding: "10px 24px",
    marginTop: theme.spacing(2),
    cursor: "pointer",
    ":focus": {
      backgroundColor: theme.palette.primary.secondary,
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
    },
    ":disabled": {
      backgroundColor: theme.palette.primary.secondary,
    },
  };

  const subtractZoom = () => {
    const scaleAux = scale > 0.2 ? parseFloat(scale) - parseFloat(0.2) : 0.2;
    setScale(scaleAux);
  };
  const addZoom = () => {
    const scaleAux = scale < 5 ? parseFloat(scale) + parseFloat(0.2) : 5;

    setScale(scaleAux);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <ContainerActionImageForm
        edit={edit}
        imgSrc={imgSrc}
        previewImg={previewImg}
      >
        <Box item>
          <Box>
            <label style={{ ...styleLabel }} for="files" class="btn">
              {!previewImg
                ? "Subir Imagen"
                : isSmall
                ? "Cambiar"
                : "Cambiar Imagen"}
            </label>
            <input
              id="files"
              onChange={onSelectFile}
              style={{ display: "none" }}
              type="file"
              accept="image/*"
            />
          </Box>
        </Box>

        {previewImg && (!edit || imgSrc) && (
          <Box>
            <ButtonCustom
              onClick={() => {
                setopen(true);
                setCrop("");
              }}
              sx={{ marginTop: "0px !important" }}
            >
              Editar imagen
            </ButtonCustom>
          </Box>
        )}
      </ContainerActionImageForm>

      <Dialog open={open} maxWidth={"sm"} fullWidth fullScreen={isSmall}>
        <DialogContent sx={{ textAlign: "center", maxHeight: "100%" }}>
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={aspect}
            locked={true}
          >
            <img
              ref={imgRef}
              alt="Crop me"
              src={imgSrc}
              style={{ transform: `scale(${scale}) `, maxHeight: "60vh" }}
              onLoad={onImageLoad}
            />
          </ReactCrop>

          <ContainerZoom>
            <ButtonBase sx={{ marginRight: 10 }} onClick={subtractZoom}>
              <MinusCircle size={32} />
            </ButtonBase>
            <ButtonBase onClick={addZoom}>
              <PlusCircle size={32} />
            </ButtonBase>
          </ContainerZoom>
          <ContainerActionCrop>
            <ButtonCustom invert inv onClick={() => setopen(false)}>
              Cancelar
            </ButtonCustom>
            <ButtonCustom
              onClick={() => {
                onDownloadCropClick();
                setopen(false);
              }}
            >
              Aceptar
            </ButtonCustom>
          </ContainerActionCrop>

          {!!completedCrop && (
            <>
              <div>
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    visibility: "hidden",
                    objectFit: "contain",
                    width: completedCrop.width,
                    height: 10,
                  }}
                />
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};
