import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import {
  dowloadData,
  getStringToBoolean,
  getUrlStoreQr,
} from "../../../utils/functions";
import { ModalViewQr } from "../../ModalViewQr/ModalViewQr";
import { Link, useParams } from "react-router-dom";
import { useAllStore } from "../../../hooks/BO/useAllStore";
import { ButtonCustom } from "../../Common.styled";
import { menuOptionBO } from "../../../utils/constants";

export const TableStore = () => {
  const { data, isLoading: isLoadinTable } = useAllStore();
  let { newQR } = useParams();

  return (
    <>
      <ButtonCustom onClick={() => dowloadData(data, menuOptionBO?.stores)}>
        Descargar txt
      </ButtonCustom>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Disponible</TableCell>
              <TableCell>Usuario</TableCell>
              <TableCell>Qr</TableCell>
              <TableCell>Link</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoadinTable ? (
              <div>
                <CircularProgress size={40} />
              </div>
            ) : (
              data?.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>
                    {getStringToBoolean(item?.created?.toString())
                      ? "No"
                      : "Si"}
                  </TableCell>
                  <TableCell>{item.email}</TableCell>
                  <TableCell>
                    <ModalViewQr
                      paramQRBO={newQR}
                      name={item?.name}
                      position={"relative"}
                      url={getUrlStoreQr(item.id)}
                      id={item?.id}
                    />
                  </TableCell>
                  <TableCell>
                    <Link to={getUrlStoreQr(item.id)}>Ir a la pagina</Link>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
