import React, { useContext } from "react";
import CurrentUserContext from "../../../../contexts/CurrentUserContext";
import { ItemListQr } from "./ItemListQr";
import { ListEmpty } from "./ListEmpty";
import { Skeleton } from "@mui/material";

export const ListQrs = () => {
  const { userData, isLoadingUser } = useContext(CurrentUserContext);
  const list = userData?.qrs;

  return (
    <>
      {list?.length > 0 ? (
        list?.map((item) => <ItemListQr item={item} key={item?.id} />)
      ) : isLoadingUser ? (
        <>
          <Skeleton height={120} />
          <Skeleton height={120} />
          <Skeleton height={120} />
        </>
      ) : (
        <ListEmpty />
      )}
    </>
  );
};
