import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { ROLES, typeObject } from "./constants";
import { doc, getDoc, setDoc } from "firebase/firestore";
import dayjs from "dayjs";
import { routes } from "./paths";
import { saveAs } from "file-saver";
import { FORMAT_DATES } from "./dates";

const provider = new GoogleAuthProvider();

export const copyText = async (texto, callbackExit) => {
  try {
    await navigator.clipboard.writeText(texto);
    callbackExit("¡Texto copiado al portapapeles!");
  } catch (error) {
    callbackExit("Error al copiar al portapapeles:", error);
  }
};

export const errorsSwitch = (errorString) => {
  if (errorString?.includes("invalid-email")) return "El email es invalido";
  if (errorString?.includes("auth/invalid-credential"))
    return "Usuario o contraseña incorrecta";
  if (errorString?.includes("email-already-in-use"))
    return "El email esta en uso";
  return errorString;
};

export const signInGoogle = async ({
  db,
  showErrorSnackbar,
  auth,
  onClose,
}) => {
  signInWithPopup(auth, provider)
    .then(async (result) => {
      const decodedToken = await getAuth().currentUser.getIdTokenResult(false);
      const { email, user_id } = decodedToken.claims;
      const docRef = doc(db, typeObject?.user, user_id);
      const docSnap = await getDoc(docRef);
      const dataUser = docSnap.data();

      const userInfo = {
        email,
        roles: [ROLES?.normal],
        creationDate: dayjs().format("DD/M/YYYY"),
        qrs: [],
        uid: user_id,
      };

      if (!dataUser) await setDoc(doc(db, typeObject?.user, user_id), userInfo);
      onClose && onClose();
    })
    .catch((error) => {
      console.error(error.code, error.message);
      showErrorSnackbar(errorsSwitch(error.code));
    });
};

export const setPetInUser = async ({ db, pet, showErrorSnackbar }) => {
  try {
    const decodedToken = await getAuth().currentUser.getIdTokenResult(false);
    const { user_id } = decodedToken.claims;

    const docRef = doc(db, typeObject?.user, user_id);
    const docSnap = await getDoc(docRef);
    const user = docSnap.data();
    const { qrs } = user || {};

    const indexQr = qrs?.findIndex((item) => item?.id === pet?.id);
    const qrFound = indexQr >= 0;
    const newValuePet = {
      uidUser: pet.uidUser || "",
      name: pet.name || "",
      description: pet.description || "",
      id: pet.id || "",
      type: pet.type || "",
      images: pet.images || "",
    };
    if (!qrFound) {
      const newUser = { ...user, qrs: [...user?.qrs, newValuePet] };
      setDoc(doc(db, typeObject?.user, user_id), newUser);
    } else {
      const dataUser = { ...user };
      if (dataUser?.qrs?.[indexQr]) dataUser.qrs[indexQr] = newValuePet;
      setDoc(doc(db, typeObject?.user, user_id), dataUser);
    }
  } catch (err) {
    console.error(err);
    showErrorSnackbar(err);
  }
};

export const getHeader = (accessToken) => {
  return {
    headers: {
      authorization: `${accessToken}`,
      "Content-Type": "application/json", // Ajusta el tipo de contenido según tus necesidades
    },
  };
};

export const getInitials = (cadena) => {
  const palabras = cadena.split(/\s+/);

  if (palabras.length === 1) {
    return palabras[0].substring(0, 2);
  } else if (palabras.length >= 2) {
    const iniciales = palabras.slice(0, 2).map((palabra) => palabra[0]);
    return iniciales.join("");
  }
};

export const isUserAdmin = (user) => {
  return user?.roles?.includes(ROLES?.superAdmin);
};

export const getStringToBoolean = (string) => {
  return JSON.parse(string || "false");
};

export const isMyQr = (qr, userData, type) => {
  const isTypeQr = qr?.type === type;

  return qr?.uidUser === userData?.uid && qr?.uidUser && isTypeQr;
};

export const getUrlPetQr = (id) => {
  return `${routes.main}${routes.pets}/${id}`;
};
export const getUrlObjectsQr = (id) => {
  return `${routes.main}${routes.objects}/${id}`;
};
export const getUrlStoreQr = (id) => {
  return `${routes.main}${routes.stores}/${id}`;
};

export const transformArrayToObject = (urls) => {
  const result = {};

  urls.forEach((url) => {
    if (url.includes("small.webp")) {
      result.small = url;
    } else if (url.includes("medium.webp")) {
      result.medium = url;
    } else if (url.includes("big.webp")) {
      result.big = url;
    }
  });

  return result;
};

export function redirigirConProtocolo(url) {
  // Verifica si la URL tiene un protocolo
  const tieneProtocolo = /^https?:\/\//i.test(url);

  // Si no tiene protocolo, agrega "https://"
  if (!tieneProtocolo) {
    return "https://" + url;
  }
  // Si ya tiene protocolo, devuelve la URL original
  return url;
}

export const validateAge = (value) => {
  if (!dayjs(value).isValid() || !dayjs(value).isAfter("1900-01-01"))
    return "El formato de fecha no es valido";
  const birthDate = new Date(value);
  const today = new Date();
  const age = today.getFullYear() - birthDate.getFullYear();
  return age >= 18 || "Debes ser mayor de 18 años";
};

export const hasUser = (user) => {
  return !!user?.uid;
};

export const dowloadData = async (data, name) => {
  try {
    const dataString = JSON.stringify(data, null, 2);
    const blob = new Blob([dataString], { type: "text/plain;charset=utf-8" });
    saveAs(blob, name + ".txt");
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
export const getDateToString = (date) => {
  return dayjs(date).format(FORMAT_DATES?.backend);
};

const getYears = (birthdate) => {
  const hoy = dayjs();
  let edad = hoy.year() - birthdate.year();
  let diferenciaMeses = hoy.month() - birthdate.month();

  if (
    diferenciaMeses < 0 ||
    (diferenciaMeses === 0 && hoy.date() < birthdate.date())
  ) {
    edad--;
  }
  return edad;
};
const getLabelYear = (edad) => {
  if (edad > 1) return `${edad} años`;
  if (edad > 0) return `${edad} año`;
};

const getMonths = (birthdate) => {
  const hoy = dayjs();
  let diferenciaDias = hoy.diff(birthdate, "day");
  const diferenciaMeses = parseInt(diferenciaDias / 30.44);
  return diferenciaMeses;
};

const getLabelMonths = (diferenciaMeses) => {
  if (diferenciaMeses > 1) return `${diferenciaMeses} meses`;
  if (diferenciaMeses > 0) return `${diferenciaMeses} mes`;
};

const getDays = (birthdate) => {
  const hoy = dayjs();
  let diferenciaDias = hoy.diff(birthdate, "day");

  return diferenciaDias;
};

const getLabelDays = (diferenciaDias) => {
  if (diferenciaDias > 1) return `${diferenciaDias} días`;
  if (diferenciaDias > 0) return `${diferenciaDias} día`;
};

export const calculateAge = (birthdate) => {
  let fechaNacimiento = new dayjs(birthdate);
  const years = getYears(fechaNacimiento);

  if (years > 0) return getLabelYear(years);

  const months = getMonths(fechaNacimiento);
  if (months > 0) return getLabelMonths(months);

  const days = getDays(fechaNacimiento);
  return getLabelDays(days);
};

export function formatPhoneNumber(phone) {
  if (!phone.startsWith("+")) {
    return `+54${phone}`;
  }
  return phone;
}
