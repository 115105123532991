import styled from "@emotion/styled";
import { theme } from "./theme";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";

export const ButtonCustom = styled(LoadingButton)(
  ({ invert, height, marginTop }) => ({
    boxSizing: "border-box !important",
    backgroundColor: invert
      ? theme.palette.primary.secondary
      : theme.palette.primary.main,
    borderRadius: 20,
    height: height || 40,
    color: invert
      ? theme.palette.primary.main
      : theme.palette.primary.secondary,
    fontWeight: 700,
    padding: theme.spacing(1, 3),
    marginTop: marginTop ? marginTop : theme.spacing(2),
    border: invert ? `1px solid ${theme.palette.primary.main}` : "",

    ":hover": {
      backgroundColor: invert
        ? theme.palette.common.hoverButtonInvert
        : theme.palette.common.hoverButton,
      color: invert ? theme.palette.primary.main : "",
      // border: invert ? `1px solid ${theme.palette.primary.secondary}` : "",
    },
    ":disabled": {
      backgroundColor: invert
        ? theme.palette.primary.main
        : theme.palette.primary.secondary,
    },
  })
);

export const ContainerGoogleButton = styled(Box)(() => ({
  border: `1px solid ${theme.palette.primary.main}`,
  width: "100%",
  display: "flex",
  alignItems: "center",
  padding: 10,
  borderRadius: 10,
  cursor: "pointer",
  justifyContent: "center",
}));

export const LinkButton = styled(Box)(() => ({
  color: theme.palette.primary.main,
  cursor: "pointer",
  ":hover": {
    textDecoration: "underline",
  },
}));
