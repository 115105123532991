import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#0297B2",
      secondary: "#EDF6FB",
    },
    common: {
      white: "white",
      lightSkyBlue: "#F6FBFF",
      hoverButtonInvert: "#D8EEFB",
      hoverButton: "#355C70",
      warningValidateEmail: "#CED6FF",
      borderBlueLight: "#dfe3e5",
      greenButton: "#A3D65D",
      blueText: "#0C494F",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: "Roboto, Arial, sans-serif", // Define aquí la familia de fuentes
    allVariants: {
      color: "#0C494F", // Color para todos los textos
    },
    h1: {
      fontFamily: "Georgia, serif", // Puedes personalizar fuentes específicas para cada tipo de texto
    },
  },
});
