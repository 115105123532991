import React, { useContext, useState } from "react";
import { TextField } from "@mui/material";
import { ButtonCustom } from "../../Common.styled";
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link } from "react-router-dom";
import { getStringToBoolean, getUrlPetQr } from "../../../utils/functions";
import { useMutation } from "react-query";
import CurrentUserContext from "../../../contexts/CurrentUserContext";
import { PetService } from "../../../services/PetsService";
import { Dialog, DialogContent } from "@mui/material";

export const SearchPet = ({ open, onClose }) => {
  const [value, setvalue] = useState();
  const [data, setdata] = useState([]);
  const { db } = useContext(CurrentUserContext);

  const mutatePetByUser = async () => {
    const response = await PetService.getPetsByUser(db, value);
    return response;
  };

  const { mutate, isLoading } = useMutation(mutatePetByUser, {
    onSuccess: (res) => setdata(res),
    onError: (err) => {
      console.error(err);
    },
  });
  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <TextField
            value={value}
            onChange={(e) => setvalue(e?.target?.value)}
          />
          <ButtonCustom
            onClick={() => {
              mutate();
              console.log(value);
            }}
          >
            Buscar
          </ButtonCustom>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Disponible</TableCell>
                  <TableCell>Usuario</TableCell>
                  <TableCell>Qr</TableCell>
                  <TableCell>Link</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <div>
                    <CircularProgress size={40} />
                  </div>
                ) : data?.length > 0 ? (
                  data?.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.id}</TableCell>
                      <TableCell>
                        {getStringToBoolean(item?.created?.toString())
                          ? "No"
                          : "Si"}
                      </TableCell>
                      <TableCell>{item.email}</TableCell>
                      {/* <TableCell>
                    <ModalViewQr
                      paramQRBO={newQR}
                      name={item?.name}
                      position={"relative"}
                      url={getUrlPetQr(item.id)}
                      id={item?.id}
                    />
                  </TableCell> */}
                      <TableCell>
                        <Link to={getUrlPetQr(item.id)}>Ir a la pagina</Link>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <div>Sin resultados</div>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
};
