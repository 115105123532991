import styled from "@emotion/styled";
import { Box, Button, Typography } from "@mui/material";
import { theme } from "../theme";
import { heightNavbar } from "../../utils/constants";

export const SaveButton = styled(Button)(() => ({
  marginTop: 20,
  marginBottom: 20,
  borderRadius: 40,
}));

export const TypographyCustom = styled(Typography)(() => ({
  height: 56,
  width: 70,
  display: "flex",
  alignItems: "center",
  marginTop: 20,
}));

export const ContainerButtonSave = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  marginBottom: 2,
}));

export const ContainerMenuBo = styled(Box)(() => ({
  width: 200,
  height: `calc(100vh - ${heightNavbar})`,
  flexDirection: "column",
  backgroundColor: theme.palette.primary.main,
}));
