import { Container, Typography } from "@mui/material";
import React from "react";
import { ListQrs } from "./ListQrs.js/ListQrs";
import { theme } from "../../theme";
import { heightNavbar } from "../../../utils/constants";

export const MyQrs = () => {
  return (
    <Container
      sx={{
        backgroundColor: theme.palette.primary.secondary,
        paddingBottom: 20,
        minHeight: `calc(100vh - ${heightNavbar})`,
      }}
      maxWidth="md"
      minHeight={"100%"}
    >
      <Typography textAlign={"start"} p={3} variant="h4" color={"primary"}>
        Mis Qrs
      </Typography>
      <ListQrs />
    </Container>
  );
};
