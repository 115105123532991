import { useQuery } from "react-query";
import { namesUseQuery } from "../../utils/constants";
import { useContext } from "react";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import { ObjectsService } from "../../services/ObjectsService";

export function useAllObjects() {
  const { db } = useContext(CurrentUserContext);

  const { data, isLoading } = useQuery(
    [namesUseQuery?.ALL_OBJECTS],
    () => ObjectsService.getAll(db),
    {
      onError: (error) => {
        console.log(error);
      },
    }
  );

  return {
    data: data,
    isLoading,
  };
}
