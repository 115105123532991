import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { theme } from "../theme";
import { Link } from "react-router-dom";

export const FooterContainer = styled(Box)(() => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.secondary,
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  paddingBottom: 50,
  justifyContent: "center",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

export const ContainerRedes = styled(Box)(() => ({
  display: "flex",
  maxWidth: 300,
  width: "100%",
  justifyContent: "space-between",
  marginLeft: 20,
  height: 80,
  alignItems: "center",

  [theme.breakpoints.down("sm")]: {
    maxWidth: 230,
    marginBottom: 50,
    marginLeft: 0,
  },
}));

export const ContainerLinks = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  height: 60,
  justifyContent: "space-between",
}));

export const ContainerLinksFooter = styled(Link)(() => ({
  textDecoration: "none",
  color: theme.palette.primary.secondary,
  cursor: "pointer",
  ":hover": {
    color: theme.palette.common.secondary,
  },
}));
