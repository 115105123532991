import { ThemeProvider } from "@mui/material";
import { theme } from "./components/theme";
// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { SnackBarProvider } from "./contexts/Snackbar/SnackbarContext";
import { CurrentUserProvider } from "./contexts/CurrentUserContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { QueryClient, QueryClientProvider } from "react-query";
import "dayjs/locale/es";

import { RouterApp } from "./RouterApp";

dayjs.locale("es");
const queryClient = new QueryClient();

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <CurrentUserProvider>
        <SnackBarProvider>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
              <RouterApp />
            </ThemeProvider>
          </QueryClientProvider>
        </SnackBarProvider>
      </CurrentUserProvider>
    </LocalizationProvider>
  );
}

export default App;
