import React from "react";
import { ButtonShopML } from "../../ButtonShopML";
import { ContainerLinksFooter } from "../../Footer/Footer.styled";
import {
  FacebookLogo,
  InstagramLogo,
  WhatsappLogo,
} from "@phosphor-icons/react";
import { routes } from "../../../utils/paths";
import { theme } from "../../theme";
import { Box, Typography } from "@mui/material";

export const BodyShops = () => {
  return (
    <>
      <ButtonShopML />

      <ContainerLinksFooter target="_blank" to={routes.instagram}>
        <Box display={"flex"} alignItems={"center"} m={1}>
          <InstagramLogo color={theme.palette.primary.main} size={32} />
          <Typography ml={2} color={theme.palette.primary.main}>
            Instagram
          </Typography>
        </Box>
      </ContainerLinksFooter>
      <ContainerLinksFooter target="_blank" to={routes.facebook}>
        <Box display={"flex"} alignItems={"center"} m={1}>
          <FacebookLogo color={theme.palette.primary.main} size={32} />
          <Typography ml={2} color={theme.palette.primary.main}>
            Facebook
          </Typography>
        </Box>
      </ContainerLinksFooter>
      <ContainerLinksFooter target="_blank" to={routes.whatsapp}>
        <Box display={"flex"} alignItems={"center"} m={1}>
          <WhatsappLogo color={theme.palette.primary.main} size={32} />
          <Typography ml={2} color={theme.palette.primary.main}>
            Whatsapp
          </Typography>
        </Box>
      </ContainerLinksFooter>
    </>
  );
};
