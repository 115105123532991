import styled from "@emotion/styled";
import { Box, Button } from "@mui/material";
import { theme } from "../theme";
import { Link } from "react-router-dom";

export const ContainerNavbar = styled(Box)(() => ({
  backgroundColor: theme.palette.primary.main,
  height: 60,
  color: theme.palette.common.white,
  display: "flex",
}));

export const BoxMenuMobile = styled(Box)(({ borderBottom }) => ({
  width: "100%",
  height: 50,
  borderTop: `1px solid ${theme.palette.primary.main}`,
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  borderBottom: borderBottom
    ? `1px solid ${theme.palette.primary.secondary}`
    : "",
  justifyContent: "center",
  fontWeight: 600,
  fontSize: 18,
}));

export const BoxMenuIcon = styled(Box)(() => ({
  width: "20%",
  alignSelf: "center",
  textAlign: "end",
  marginRight: theme.spacing(2),
}));

export const BoxAvatar = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  alignSelf: "center",
  marginRight: theme.spacing(2),
  cursor: "pointer",
}));

export const ContainerMenuPopper = styled(Box)(() => ({
  backgroundColor: theme.palette.primary.main,
  marginTop: theme.spacing(1),
  color: theme.palette.common.white,
  borderTop: `1px solid ${theme.palette.primary.secondary}`,
}));

export const ButtonMenuMobile = styled(Box)({
  marginRight: 10,
  textDecoration: "none",
  color: theme.palette.primary.secondary,
  width: "100%",
  fontWeight: 600,
  fontSize: 18,
  cursor: "pointer",
});
export const ItemMenuMobile = styled(Link)({
  textDecoration: "none",
  color: theme.palette.common.blueText,
  width: "100%",
  fontWeight: 600,
  fontSize: 18,
  cursor: "pointer",
  display: "flex",
  justifyContent: "left",
  alignItems: "center",
  marginLeft: 16,
});
export const BoxItemMenuMobile = styled(Box)(({ borderBottom }) => ({
  width: "90%",
  height: 80,
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  border: `1px solid ${theme.palette.common.borderBlueLight}`,
  borderRadius: 10,
  justifyContent: "center",
  marginBottom: 10,
}));

export const LinkMenuMobile = styled(Link)({
  marginRight: 10,
  textDecoration: "none",
  color: theme.palette.primary.secondary,
  width: "100%",
  fontWeight: 600,
  fontSize: 18,
  cursor: "pointer",
});

export const LoginButton = styled(Button)({
  backgroundColor: theme.palette.primary.secondary,
  borderRadius: 20,
  padding: theme.spacing(1, 3),
  fontWeight: 800,
  fontSize: 12,
  ":hover": {
    backgroundColor: theme.palette.primary.secondary,
  },
});

export const ButtonRegister = styled(Button)({
  backgroundColor: theme.palette.primary.primary,
  borderRadius: 20,
  padding: theme.spacing(1, 3),
  fontWeight: 800,
  marginLeft: theme.spacing(1),
  border: `1px solid ${theme.palette.primary.secondary}`,
  color: theme.palette.primary.secondary,
  fontSize: 12,
});
