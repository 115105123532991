import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React, { useContext, useState } from "react";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import { X } from "@phosphor-icons/react";
import { sendEmailVerification } from "@firebase/auth";
import { ButtonLoading } from "../ButtonLoading";

export const ValidateEmail = ({ style }) => {
  const { userData, auth } = useContext(CurrentUserContext);
  const [isLoading, setisLoading] = useState(false);
  const [open, setopen] = useState(false);

  const validateEmail = () => {
    setisLoading(true);
    sendEmailVerification(auth.currentUser).then(() => {
      setisLoading(false);
      setopen(true);
    });
  };

  return (
    <div>
      <ButtonLoading
        invert
        style={{ ...style }}
        isLoading={isLoading}
        onClick={validateEmail}
      >
        Validar email
      </ButtonLoading>
      <Dialog open={open}>
        <DialogTitle display={"flex"} justifyContent={"space-between"}>
          Validación de email
          <IconButton onClick={() => setopen(false)}>
            <X size={20} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          Se envio un mail a la direccion <b> {userData?.email}</b> para validar
          tu identidad. Si no lo recibe, por favor revise la casilla de "spam"
        </DialogContent>
      </Dialog>
    </div>
  );
};
