import React from "react";
import {
  ContainerList,
  ContainerScanner,
  ContainerUser,
} from "../QRScannerCircleIcon/QRScannerCircleIcon.styled";
import { ListBullets, User } from "@phosphor-icons/react";
import { LinkMenuMobile } from "../NavBar/Navbar.styled";
import { routes } from "../../utils/paths";

export const BarScanner = ({ onClick }) => {
  return (
    <ContainerScanner>
      <LinkMenuMobile to={routes.profile}>
        <ContainerUser>
          <User size={22} />
        </ContainerUser>
      </LinkMenuMobile>
      <LinkMenuMobile to={routes.myqrs}>
        <ContainerList>
          <ListBullets size={22} />
        </ContainerList>
      </LinkMenuMobile>
    </ContainerScanner>
  );
};
