import styled from "@emotion/styled";
import { Box, Button, Card, Container, Typography } from "@mui/material";
import { theme } from "../../theme";
import { heightNavbar } from "../../../utils/constants";

export const ContainerTitle = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 26,
}));

export const BoxCard = styled(Box)(() => ({
  width: "100%",
  placeContent: "center",
  display: "flex",
  paddingTop: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    padding: 0,
  },
}));

export const ContainerAge = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 18,
}));

export const ContainerDescription = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 500,
  padding: theme.spacing(2, 8),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2, 2),
  },
}));

export const ContainerPet = styled(Container)(() => ({
  textAlign: "center",
  paddingBottom: 30,
  display: "flex",
  justifyContent: "center",
  minHeight: `calc(100vh - ${heightNavbar})`,
}));

export const CardImage = styled(Card)(() => ({
  maxWidth: 400,
  maxHeight: 400,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export const TitleRegister = styled(Box)(() => ({
  textAlign: "center",
  fontWeight: 700,
  fontSize: 30,
  color: theme.palette.primary.main,
}));

export const CodeArgPhone = styled(Typography)(() => ({
  marginRight: 8,
  minWidth: 50,
  alignSelf: "center",
  textAlign: "end",
}));

export const ButtonPet = styled(Button)(() => ({
  backgroundColor: theme.palette.common.greenButton,
  color: theme.palette.common.blueText,
  borderRadius: 20,
  padding: "0px 20px",
  fontSize: 30,
  minWidth: 250,
}));
