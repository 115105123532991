import { Paper, Typography } from "@mui/material";
import React from "react";
import { ItemWorking } from "../../Working/ItemWorking";
import { ITEM_WORKING } from "../../../../utils/constants";

export const ListEmpty = () => {
  return (
    <Paper sx={{ borderRadius: 5, padding: 3, textAlign: "center" }}>
      <Typography variant="h5" color="primary">
        Todavia no tenes QRs activos
      </Typography>
      <ItemWorking
        label={"¡Quiero mi QR!"}
        text={ITEM_WORKING.SHOPS.text}
        body={ITEM_WORKING.SHOPS.body}
        modeButton
      />
    </Paper>
  );
};
