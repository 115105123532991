import { Box, ClickAwayListener, Popper } from "@mui/material";
import { Z_INDEX } from "../../utils/constants";
import { theme } from "../theme";
import { useContext } from "react";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import { isUserAdmin } from "../../utils/functions";
import {
  BoxMenuMobile,
  ButtonMenuMobile,
  LinkMenuMobile,
} from "./Navbar.styled";
import { routes } from "../../utils/paths";

export const PopperMenu = ({ handleClose, open, anchorEl }) => {
  const { logOut, userData } = useContext(CurrentUserContext);
  const isAdmin = isUserAdmin(userData);

  return (
    <Popper
      sx={{
        width: 200,
        zIndex: Z_INDEX?.HIGH,
        backgroundColor: theme.palette.primary.main,
        right: "-100px",
      }}
      open={open}
      anchorEl={anchorEl.current}
    >
      <ClickAwayListener onClickAway={handleClose}>
        <Box>
          <BoxMenuMobile borderBottom>
            <LinkMenuMobile onClick={handleClose} to={routes.profile}>
              Mi perfil
            </LinkMenuMobile>
          </BoxMenuMobile>
          <BoxMenuMobile borderBottom>
            <LinkMenuMobile onClick={handleClose} to={routes.myqrs}>
              Mis QRs
            </LinkMenuMobile>
          </BoxMenuMobile>
          <BoxMenuMobile borderBottom>
            <ButtonMenuMobile
              onClick={() => {
                handleClose();
                logOut();
              }}
            >
              Cerrar sesión
            </ButtonMenuMobile>
          </BoxMenuMobile>
          {isAdmin && (
            <BoxMenuMobile>
              <LinkMenuMobile onClick={handleClose} to={routes.adminUsers}>
                Administración
              </LinkMenuMobile>
            </BoxMenuMobile>
          )}
        </Box>
      </ClickAwayListener>
    </Popper>
  );
};
