import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const BoxUrlRedirect = styled(Box)(() => ({
  position: "absolute",
  backgroundColor: "white",
  top: "50%",
  width: "100%",
  zIndex: 3,
  minHeight: "50vh",
  textAlign: "center",
}));

export const BoxClose = styled(Box)(() => ({
  display: "flex",
  justifyContent: "end",
}));
