import React, { useContext, useState } from "react";
import CurrentUserContext from "../../../contexts/CurrentUserContext";
import { namesUseQuery } from "../../../utils/constants";
import { TablePets } from "./TablePets";
import { PetService } from "../../../services/PetsService";
import { useMutation, useQueryClient } from "react-query";
import { ContainerTitle } from "./PetsBO.styled";
import { ButtonLoading } from "../../ButtonLoading";
import { useNavigate } from "react-router";
import { routes } from "../../../utils/paths";
import { useParams } from "react-router-dom";
import { ModalViewQr } from "../../ModalViewQr/ModalViewQr";
import { getUrlPetQr } from "../../../utils/functions";
import { useCountPets } from "../../../hooks/BO/useCountPets";
import { ButtonCustom } from "../../Common.styled";
import { SearchPet } from "./SearchPet";

export const PetsBO = () => {
  const [viewAll, setviewAll] = useState(false);
  const [openBusqueda, setOpenBusqueda] = useState(false);

  const { userData } = useContext(CurrentUserContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  let { newQR } = useParams();

  const mutateGenerateQR = async () => {
    const response = await PetService.generateQR(userData?.accessToken);
    return response?.data?.id;
  };

  const { mutate, isLoading } = useMutation(mutateGenerateQR, {
    onSuccess: (id) => {
      navigate(routes.adminPet + "/" + id);
      queryClient.invalidateQueries(namesUseQuery?.ALL_PETS);
    },
    onError: (err) => {
      console.error(err);
    },
  });
  const { data } = useCountPets();

  return (
    <div>
      <ContainerTitle>
        <h1>Mascotas</h1>
        <ButtonLoading
          isLoading={isLoading}
          onClick={() => mutate(userData?.accessToken)}
          sx={{ height: 40 }}
        >
          Generar Nueva mascota
        </ButtonLoading>
        <ModalViewQr
          paramQRBO={newQR}
          name={newQR}
          position={"relative"}
          url={getUrlPetQr(newQR)}
          id={newQR}
        />
      </ContainerTitle>
      <ButtonCustom
        onClick={() => {
          setOpenBusqueda(true);
        }}
      >
        Busqueda
      </ButtonCustom>
      {openBusqueda && (
        <SearchPet open={openBusqueda} onClose={() => setOpenBusqueda(false)} />
      )}

      <h4>Cantidad de mascotas registradas: {data}</h4>
      <ButtonCustom onClick={() => setviewAll(true)}>
        Mostrar todos
      </ButtonCustom>
      {viewAll && <TablePets />}
    </div>
  );
};
