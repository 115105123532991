import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { NotFound } from "../../404NotFound/NotFound";
import CurrentUserContext from "../../../contexts/CurrentUserContext";
import { getStringToBoolean } from "../../../utils/functions";
import { NewQr } from "../../NewQr/NewQr";
import { useGetStoreById } from "../../../hooks/Store/useGetStoreById";
import { Store } from "./Store";
import { typeObject } from "../../../utils/constants";
import { Box, CircularProgress } from "@mui/material";

const ProfileStore = () => {
  const { app, db } = useContext(CurrentUserContext);
  let { id } = useParams();
  const { data, isLoading } = useGetStoreById(id);
  const notFount = !isLoading && !data;
  const created = getStringToBoolean(data?.created);

  if (isLoading)
    return (
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        minHeight={400}
      >
        <CircularProgress size={100} mt={10} />
      </Box>
    );

  if (notFount) return <NotFound />;

  if (created) return <Store data={data} />;

  if (!created)
    return <NewQr type={typeObject?.stores} id={id} app={app} db={db} />;
};

export default ProfileStore;
