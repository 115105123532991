import { Container, Dialog, DialogContent, useMediaQuery } from "@mui/material";
import React, { useContext, useState } from "react";
import { BoxActions, BoxDetail, BoxSubtitle } from "./NewQr.styled";
import { theme } from "../theme";
import { ButtonCustom } from "../Common.styled";
import { SwitchModalLogin } from "../SwitchModalLogin.js/SwitchModalLogin";
import {
  heightNavbar,
  namesUseQuery,
  newQRmenu,
  typeObject,
} from "../../utils/constants";
import { useQueryClient } from "react-query";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import { RegisterPet } from "../pages/Pets/NewPet/RegisterPet";
import { RegisterObject } from "../pages/Objects/NewObject/RegisterObject";
import { RegisterStore } from "../pages/Stores/NewStore/RegisterStore";

export const NewQr = ({ type }) => {
  const { userData, isLoadingUser } = useContext(CurrentUserContext);

  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [menu, setMenu] = useState(newQRmenu.newQR);
  const queryClient = useQueryClient();

  const showModalLogin =
    newQRmenu.login === menu || newQRmenu.register === menu;

  const newQR = newQRmenu.newQR === menu;
  const hasUser = !!userData;

  if (!hasUser && !isLoadingUser)
    return (
      <Container sx={{ height: `calc(100vh - ${heightNavbar})`, padding: 4 }}>
        {newQR && (
          <>
            <BoxSubtitle> Adquiriste un nuevo QR</BoxSubtitle>

            <BoxDetail>
              Para darlo de alta tenes que crear una cuenta en BuscaQR.
              <BoxActions>
                <ButtonCustom onClick={() => setMenu(newQRmenu.register)}>
                  Crear una cuenta
                </ButtonCustom>
                <ButtonCustom invert onClick={() => setMenu(newQRmenu.login)}>
                  Ya tengo cuenta
                </ButtonCustom>
              </BoxActions>
            </BoxDetail>
          </>
        )}
        <Dialog open={showModalLogin} fullScreen={isSmall} fullWidth>
          <DialogContent>
            {showModalLogin && (
              <SwitchModalLogin
                onClose={() =>
                  queryClient.invalidateQueries(namesUseQuery?.getPetById)
                }
                option={menu}
                backOnClick={() => setMenu(newQRmenu?.newQR)}
              />
            )}
          </DialogContent>
        </Dialog>
      </Container>
    );
  if (typeObject?.objects === type) return <RegisterObject />;
  if (typeObject?.stores === type) return <RegisterStore />;
  return <RegisterPet />;
};
