import { Box } from "@mui/material";
import { Image } from "@phosphor-icons/react";
import React from "react";

export const PreviewImageObject = ({ previewImg }) => {
  return (
    <Box display={"flex"} justifyContent={"center"} mt={1}>
      {previewImg ? (
        <img
          style={{
            borderRadius: 20,
            marginBottom: 20,
            aspectRatio: "4/3",
            width: "80%",
          }}
          src={previewImg}
          alt="asd"
        />
      ) : (
        <Box>
          <Image size={200} />
        </Box>
      )}
    </Box>
  );
};
