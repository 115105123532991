import { switchModalLoginConstant } from "../../utils/constants";
import { Login } from "../pages/Login/Login";
import { Register } from "../pages/Register/Register";
import { Box, ButtonBase, DialogTitle, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { X } from "@phosphor-icons/react";

export const SwitchModalLogin = ({
  option,
  closeActive = false,
  onClose,
  backOnClick,
}) => {
  const header = (
    <Box display={"flex"} justifyContent={closeActive ? "flex-end" : ""}>
      {backOnClick && (
        <ButtonBase onClick={backOnClick}>
          <ArrowBackIcon />
        </ButtonBase>
      )}
      {closeActive && (
        <ButtonBase onClick={onClose}>
          <X size={32} />
        </ButtonBase>
      )}
    </Box>
  );

  if (option === switchModalLoginConstant?.login)
    return (
      <Box>
        {header}
        <DialogTitle textAlign={"center"}>
          <Typography fontSize={30} color="primary">
            Iniciar sesión
          </Typography>
        </DialogTitle>
        <Login onClose={onClose} />
      </Box>
    );
  if (option === switchModalLoginConstant?.register)
    return (
      <Box>
        {header}
        <DialogTitle textAlign={"center"}>
          <Typography fontSize={30} color="primary">
            Crear cuenta en BuscaQR
          </Typography>
        </DialogTitle>
        <Register onClose={onClose} />
      </Box>
    );
};
