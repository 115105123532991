import styled from "@emotion/styled";
import { theme } from "../theme";
import { Box } from "@mui/material";

export const ContainerActionCrop = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

export const ContainerZoom = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  marginTop: theme.spacing(2),
}));

export const ContainerActionImageForm = styled(Box)(
  ({ previewImg, edit, imgSrc }) => ({
    display: "flex",
    justifyContent:
      previewImg && (!edit || imgSrc) ? "space-between" : "center",
    alignItems: "center",
    margin: theme.spacing(1, 0),
  })
);
