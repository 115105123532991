import {
  Avatar,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import { Link } from "react-router-dom";
import { routes } from "../../utils/paths";
import { BoxAvatar, BoxMenuIcon, ContainerNavbar } from "./Navbar.styled";
import { TitleNavbar } from "./TitleNavbar";
import { theme } from "../theme";
import { PopperMenuMobile } from "./PopperMenuMobile";
import MenuIcon from "@mui/icons-material/Menu";
import {
  getInitials,
  hasUser,
  getStringToBoolean,
} from "../../utils/functions";
import { MenuNewAccount } from "./MenuNewAccount";
import { PopperMenu } from "./PopperMenu";
import { switchModalLoginConstant } from "../../utils/constants";
import { SwitchModalLogin } from "../SwitchModalLogin.js/SwitchModalLogin";
import { ValidateEmailWarning } from "../ValidateEmail/ValidateEmailWarning";

export const NavBar = () => {
  const { userData, isLoadingUser } = useContext(CurrentUserContext);
  const [open, setopen] = useState(false);
  const [option, setOption] = useState(switchModalLoginConstant?.login);

  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { displayName } = userData || {};
  const [openPopper, setopenPopper] = useState(false);
  const anchorRef = useRef(null);
  const anchorRefAux = useRef(null);

  const handleClick = () => {
    setopenPopper((prevState) => {
      return !prevState;
    });
  };

  const warningValidateEmail =
    hasUser(userData) && !getStringToBoolean(userData?.emailVerified);

  return (
    <>
      <ContainerNavbar>
        <Box sx={{ width: "80%" }}>
          <Link to={routes.home}>
            <TitleNavbar />
          </Link>
        </Box>
        {!isSmall &&
          (userData ? (
            <BoxAvatar onClick={handleClick}>
              <Avatar src={userData?.photoURL} ref={anchorRefAux}>
                {displayName && !userData?.photoURL && getInitials(displayName)}
              </Avatar>
            </BoxAvatar>
          ) : (
            <BoxAvatar>
              {isLoadingUser ? (
                <LoadingUser />
              ) : (
                <MenuNewAccount setopen={setopen} setOption={setOption} />
              )}
            </BoxAvatar>
          ))}

        {isSmall &&
          (isLoadingUser ? (
            <LoadingUser />
          ) : (
            <BoxMenuIcon onClick={handleClick} ref={anchorRef}>
              <MenuIcon
                sx={{ color: theme.palette.primary.secondary }}
                fontSize={"large"}
              />
            </BoxMenuIcon>
          ))}
        {isSmall && (
          <PopperMenuMobile
            setopen={setopen}
            setOption={setOption}
            open={openPopper}
            anchorEl={anchorRef}
            handleClose={() => setopenPopper(false)}
          />
        )}

        {!isSmall && (
          <PopperMenu
            open={openPopper}
            anchorEl={anchorRefAux}
            handleClose={() => setopenPopper(false)}
          />
        )}
        {open && (
          <Dialog open={true} fullWidth fullScreen={isSmall}>
            <DialogContent>
              <SwitchModalLogin
                option={option}
                closeActive={true}
                onClose={() => setopen(false)}
                setOption={setOption}
              />
            </DialogContent>
          </Dialog>
        )}
      </ContainerNavbar>
      {warningValidateEmail && <ValidateEmailWarning />}
    </>
  );
};

const LoadingUser = () => {
  return (
    <Box display={"flex"} alignItems={"center"}>
      <CircularProgress sx={{ color: theme.palette.primary.secondary }} />
    </Box>
  );
};
