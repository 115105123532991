import { switchModalLoginConstant } from "../../utils/constants";
import { ButtonCustom } from "../Common.styled";

export const MenuNewAccount = ({ setopen, setOption }) => {
  return (
    <div>
      <ButtonCustom
        invert
        onClick={() => {
          setopen(true);
          setOption(switchModalLoginConstant?.login);
        }}
        marginTop={"0px"}
        type="link"
      >
        Iniciar sesión
      </ButtonCustom>

      <ButtonCustom
        marginTop={"0px"}
        onClick={() => {
          setopen(true);
          setOption(switchModalLoginConstant?.register);
        }}
      >
        Registrarse
      </ButtonCustom>
    </div>
  );
};
