import { createContext, useContext, useState } from "react";
import { COMMON_MESSAGES } from "../../utils/constants";
import AppSnackbar from "./AppSnackbar";

export const SnackBarContext = createContext();

export const SNACKBAR_TYPES = {
  error: "error",
  success: "success",
};

export function SnackBarProvider({ children }) {
  const initialValues = {
    message: "",
    open: false,
    type: SNACKBAR_TYPES.error,
  };
  const [snackbar, setSnackbar] = useState(initialValues);

  function showSuccessSnackbar(message = COMMON_MESSAGES.success) {
    const messageCurated =
      typeof message === "string" ? message : COMMON_MESSAGES.success;
    setSnackbar({
      open: true,
      type: SNACKBAR_TYPES.success,
      message: messageCurated,
    });
  }

  const showErrorSnackbar = (message = COMMON_MESSAGES.error) => {
    const messageCurated =
      typeof message === "string" ? message : COMMON_MESSAGES.error;
    setSnackbar({
      open: true,
      type: SNACKBAR_TYPES.error,
      message: messageCurated,
    });
  };

  const addSnackbar = ({ open = true, type, message }) =>
    setSnackbar({ open, type, message });

  const value = { showErrorSnackbar, showSuccessSnackbar, addSnackbar };

  return (
    <SnackBarContext.Provider value={value}>
      {children}
      <AppSnackbar snackbar={snackbar} setSnackbar={setSnackbar} />
    </SnackBarContext.Provider>
  );
}

// Custom Hooks
export const useSnackBars = () => useContext(SnackBarContext);
