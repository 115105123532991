import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { CaretDown } from "@phosphor-icons/react";
import React from "react";
import { TextFieldBOValidate } from "../../../TextFieldBO/TextFieldBOValidate";

export const AccordionDatosBancarios = ({
  register,
  errors,
  alias,
  emailMP,
}) => {
  const { emailMP: errorMP } = errors;
  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<CaretDown size={32} />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography fontWeight={700} color={errorMP ? "red" : "primary"}>
            Datos para transferencia
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Asegurese que los datos para la transferencia sean correctos. Se
            recomienda hacer una transferencia de prueba luego de guardar los
            cambios.
          </Typography>
          <TextFieldBOValidate
            register={register}
            name="alias"
            label={"Alias"}
            errors={errors}
            maxLength={30}
            length={alias?.length}
          />
          <TextFieldBOValidate
            register={register}
            name="emailMP"
            label={"Email para transferir a mercado pago"}
            errors={errors}
            maxLength={200}
            length={emailMP?.length}
            isEmail
          />
        </AccordionDetails>
      </Accordion>
      {errorMP && (
        <Typography color={"red"}>
          * Error en los datos para transferencia
        </Typography>
      )}
    </>
  );
};
