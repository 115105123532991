import React, { useContext, useEffect } from "react";
import {
  BoxCard,
  ContainerDescription,
  ContainerPet,
  ContainerTitle,
} from "../Pets/Pets.styled";
import { Box, useMediaQuery } from "@mui/material";
import { useParams } from "react-router";
import CurrentUserContext from "../../../contexts/CurrentUserContext";
import { theme } from "../../theme";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Image } from "@phosphor-icons/react/dist/ssr";
import { getUrlStoreQr, isMyQr } from "../../../utils/functions";
import { typeObject } from "../../../utils/constants";
import { ModalViewQr } from "../../ModalViewQr/ModalViewQr";
import { EditStore } from "./EditStore/EditStore";
import { ButtonWhatsapp } from "../../ButtonWhatsapp/ButtonWhatsapp";
import { AccordionStore } from "./AccordionStore";
import { AccordionPerfilRedesSociales } from "./AccordionPerfilRedesSociales";

export const Store = ({ data }) => {
  let { id } = useParams();
  const { userData } = useContext(CurrentUserContext);

  const {
    title,
    images,
    description,
    alias,
    cel,
    emailMP,
    instagram,
    facebook,
  } = data || {};
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const owner = isMyQr(data, userData, typeObject.stores);

  useEffect(() => {
    const elemento = document?.getElementById("redesBuscaQR");
    if (elemento) {
      elemento.style.display = "none";
    }
  }, []);

  return (
    <ContainerPet disableGutters maxWidth={"sm"}>
      <Box>
        <Box sx={{ position: "relative" }}>
          {images ? (
            <BoxCard>
              <LazyLoadImage
                alt={"Imagen de perfil de" + title}
                effect="blur"
                src={isSmall ? images?.medium : images?.big}
                width={"100%"}
                style={{
                  objectFit: "cover",
                  borderRadius: isSmall ? 0 : 20,
                  aspectRatio: "4/3",
                }}
              />
            </BoxCard>
          ) : (
            <Box>
              <Image size={200} />
            </Box>
          )}
          {owner && <EditStore data={data} />}
          <ModalViewQr name={title} url={getUrlStoreQr(id)} />
        </Box>
        <ContainerTitle> {title}</ContainerTitle>

        <ContainerDescription> {description}</ContainerDescription>

        {(alias || emailMP) && (
          <AccordionStore alias={alias} emailMP={emailMP} />
        )}

        {(instagram || facebook) && (
          <Box mt={2}>
            <AccordionPerfilRedesSociales
              instagram={instagram}
              facebook={facebook}
            />
          </Box>
        )}

        {cel && (
          <Box display={"flex"} justifyContent={"center"} mb={2}>
            <ButtonWhatsapp
              text={"Hola! escanee el QR de " + title}
              cel={cel}
            />
          </Box>
        )}
      </Box>
    </ContainerPet>
  );
};
