import axios from "axios";
import { getHeader } from "../utils/functions";
import { collection, getDocs } from "firebase/firestore";
import { typeObject } from "../utils/constants";
import { where, query, getCountFromServer } from "firebase/firestore";

export class Store {
  constructor() {
    this.axios = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}stores`, // URL base de la API
    });
  }

  getStoreById(id) {
    return this.axios.get(`/${id}`);
  }
  setStore({ id, body, token }) {
    return this.axios.put(`/edit/${id}`, body, getHeader(token));
  }
  generateQR(token) {
    return this.axios.put(`/create-emtpy-objet`, {}, getHeader(token));
  }
  getAll = async (db) => {
    try {
      const querySnapshot = await getDocs(collection(db, typeObject?.stores));
      return querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.error("Error al obtener documentos:", error);
    }
  };
  getCountStoresRegisters = async (db) => {
    try {
      const coll = collection(db, typeObject?.stores);
      const q = query(coll, where("created", "==", true));
      const snapshot = await getCountFromServer(q);
      const count = snapshot.data().count;
      return count;
    } catch (error) {
      console.error("Error al obtener documentos:", error);
    }
  };
  getStoreByUser = async (db, email) => {
    try {
      console.log(email);
      const queryCustom = query(
        collection(db, typeObject?.stores),
        where("email", "==", email)
      );
      const snapshot = await getDocs(queryCustom);
      const stores = snapshot.docs.map((doc) => doc.data());
      return stores;
    } catch (error) {
      console.error("Error al obtener documentos:", error);
    }
  };
}

export const StoresService = new Store();
