import React from "react";
import {
  ContainerText,
  ContainerValidateEmailWarning,
} from "./Validate.styled";
import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import { ValidateEmail } from "./ValidateEmail";
import { theme } from "../theme";
import { Warning } from "@phosphor-icons/react";

export const ValidateEmailWarning = () => {
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <ContainerValidateEmailWarning>
      <Container
        sx={{ alignSelf: "center", display: "flex", justifyContent: "center" }}
      >
        <ContainerText display={"flex"}>
          <Box display={"flex"}>
            <Warning size={32} />

            <Typography
              alignSelf={"center"}
              ml={2}
              variant={isSmall ? "h8" : "h7"}
            >
              {isSmall ? "Recomendado" : "Se recomienda validar email"}
            </Typography>
          </Box>
          <ValidateEmail
            style={{
              marginTop: "0px !important",
              whiteSpace: "nowrap",
              [theme.breakpoints.down("sm")]: {
                width: 128,
              },
            }}
          />
        </ContainerText>
      </Container>
    </ContainerValidateEmailWarning>
  );
};
