import { LazyLoadImage } from "react-lazy-load-image-component";
import { BodyShops } from "../components/pages/Working/BodyShops";

export const stylesUseSnackbar = {
  error: {
    position: "bottom-right",
    style: {
      backgroundColor: "red",

      color: "white",
      fontSize: "20px",
      textAlign: "center",
    },
    closeStyle: {
      color: "white",
      fontSize: "16px",
    },
  },
  successful: {
    position: "bottom-right",
    style: {
      backgroundColor: "green",

      color: "white",
      fontSize: "20px",
      textAlign: "center",
    },
    closeStyle: {
      color: "white",
      fontSize: "16px",
    },
  },
};

export const errorMenssages = {
  "auth/invalid-credential": "Usuario o contraseña incorrecta",
};

export const COMMON_MESSAGES = { error: "error", success: "exito" };

export const ROLES = {
  normal: "normal",
  superAdmin: "superAdmin",
};

export const typeObject = {
  user: "usuarios",
  pets: "pets",
  objects: "objects",
  stores: "stores",
};

export const switchModalLoginConstant = {
  login: "login",
  register: "register",
};

export const Z_INDEX = {
  LOW: 1,
  MEDIUM: 2,
  HIGH: 3,
};

export const namesUseQuery = {
  getPetById: "getPetById",
  ALL_PETS: "all_pets",
  ALL_OBJECTS: "all_objects",
  GET_OBJECT_BY_ID: "get_object_by_id",
  GET_STORE_BY_ID: "get_object_by_id",
  GET_COUNT_PETS: "get_count_pets",
  GET_PET_BY_USER: "get_pet_by_user",
  GET_COUNT_BY_USERS: "get_count_by_users",
};

export const defaultDescriptionPets =
  "Me he perdido y mi familia me extraña mucho. Si escaneaste este QR , por favor avísales utilizando los botones de contacto debajo. ¡Gracias por tu ayuda!";

export const defaultDescriptionObjects =
  "Si encontraste este QR, posiblemente se me perdio. Te agradeceria si te contactas para tenerlo conmigo nuevamente!";

export const defaultDescriptionStores = "Bienvenido al QR de mi negocio!";

export const typeAgePets = [
  { label: "Años", value: "Años" },
  { label: "Meses", value: "Meses" },
];

export const menuOptionBO = {
  users: "users",
  pets: "pets",
  objects: "objects",
  stores: "stores",
};

export const newQRmenu = {
  newQR: "newQR",
  register: "register",
  login: "login",
};

export const namesProfilePhoto = ["big", "medium", "small"];

export const heightNavbar = "60px";

export const ITEM_WORKING = {
  QUE_ES:
    "Busca QR es una plataforma que te permite vincular tus mascotas y objetos del mundo físico con el mundo digital. Con nuestra plataforma, puedes registrarlos y asignarles datos para facilitar su ubicación en caso de extravío, lo que simplifica el proceso para quienes los encuentren, permitiéndoles localizarte de manera rápida y sencilla.",
  COMO_FUNCIONA:
    "Imaginemos la historia de Milo, un animado perrito que se extravía durante un emocionante paseo en el parque.\n\n Por suerte, Milo lleva consigo su identificador BQR, donde están los datos de contacto de su dueño.\n\n Una persona encuentra a Milo y decide escanear el colgante con su teléfono. En apenas unos segundos, accede a la información de contacto de los dueños de Milo y se comunica con ellos para informarles que han encontrado a su querida mascota.\n\n Gracias al identificador BQR, Milo regresa rápidamente a casa, brindando a sus dueños la tranquilidad de saber que su adorado mascota está a salvo.",
  COMO_CARGAR_QR: {
    text: "-	Escaneá el QR de tu identificador BQR.\n\n -	Crea una cuenta en BuscaQR. \n\n -	Luego te pedirá los datos de tu mascota y ¡ya tenés listo el perfil digital de tu mascota! ",
    body: (
      <iframe
        width="100%"
        height="315"
        src="https://www.youtube.com/embed/clTXZslnyTc?si=xGEewOx3JJ2UZhSK"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    ),
  },
  EDIT: {
    body: (
      <iframe
        width="100%"
        height="315"
        src="https://www.youtube.com/embed/95D07pG5nyw?si=5Wt2qkzQ6lONzjq8"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    ),
  },
  DATA: {
    text: "Podrás ingresar información importante sobre tu mascota, incluyendo su nombre, edad, número de teléfono, correo electrónico y una descripción de hasta 300 caracteres. En esta descripción, puedes incluir detalles como el tipo de alimento que consume tu mascota, cualquier condición médica relevante y cualquier otro dato que el dueño considere necesario.",
    body: (
      <div>
        <LazyLoadImage
          alt={"Perfil muestra"}
          effect="blur"
          src={"./images/ProfileInPhone.png"}
          style={{
            width: "100%",
          }}
          onLoad={() => console.log("onLoad")}
          threshold={100}
        />
      </div>
    ),
  },
  SHOPS: {
    text: "Podes comprar desde nuestra tienda online o contactarnos por nuestras redes sociales.",
    body: <BodyShops />,
  },
};
