import { Box, Dialog, DialogTitle, IconButton } from "@mui/material";
import { switchModalLoginConstant } from "../../utils/constants";
import { isUserAdmin } from "../../utils/functions";
import { signOut } from "firebase/auth";
import { useContext } from "react";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import { X } from "@phosphor-icons/react/dist/ssr";
import { ItemButtonMenu, PopperHasUser } from "./PopperHasUser";
import { SignIn, UserCirclePlus } from "@phosphor-icons/react";
import { routes } from "../../utils/paths";

export const PopperMenuMobile = ({
  anchorEl,
  open,
  handleClose,
  setopen,
  setOption,
}) => {
  const { userData, logOut, auth } = useContext(CurrentUserContext);

  const { displayName, photoURL } = userData || {};

  const userSignOut = () => {
    signOut(auth)
      .then(() => {
        console.log("sign out successful");
        handleClose();
        logOut();
      })
      .catch((error) => console.log(error));
  };
  const isAdmin = isUserAdmin(userData);
  const openLogin = () => {
    setopen(true);
    setOption(switchModalLoginConstant?.login);
    handleClose();
  };
  const openRegister = () => {
    setopen(true);
    setOption(switchModalLoginConstant?.register);
    handleClose();
  };

  return (
    <>
      <Dialog open={open} fullScreen>
        <DialogTitle display={"flex"} justifyContent={"flex-end"}>
          <IconButton onClick={handleClose}>
            <X size={30} />
          </IconButton>
        </DialogTitle>
        {!userData && (
          <Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
            <ItemButtonMenu
              icon={<SignIn size={36} />}
              text={"Iniciar sesión"}
              to={routes.home}
              handleClose={openLogin}
            />
            <ItemButtonMenu
              icon={<UserCirclePlus size={36} />}
              text={"Registrarse"}
              to={routes.home}
              handleClose={openRegister}
            />
          </Box>
        )}
        {userData && (
          <PopperHasUser
            displayName={displayName}
            photoURL={photoURL}
            handleClose={handleClose}
            isAdmin={isAdmin}
            userSignOut={userSignOut}
          />
        )}
      </Dialog>
    </>
  );
};
