import { useQuery } from "react-query";
import { namesUseQuery } from "../../utils/constants";
import { ObjectsService } from "../../services/ObjectsService";

export function useGetObjectById(id) {
  const { data, isLoading } = useQuery(
    [namesUseQuery?.GET_OBJECT_BY_ID, id],
    () => ObjectsService.getObjectById(id),
    {
      enabled: true,
      onError: (error) => {
        console.log(error);
      },
    }
  );
  return {
    data: data?.data,
    isLoading,
  };
}
