import React from "react";
import { Typography } from "@mui/material";
import { ButtonPet } from "../pages/Pets/Pets.styled";

export const ButtonWhatsapp = ({
  cel,
  label = "Enviar Whatsapp",
  text = "Hola",
}) => {
  const generateLinkWhatsapp = () => {
    return `https://api.whatsapp.com/send/?phone=${cel}&text=${text}`;
  };
  const onClick = () => {
    window.open(generateLinkWhatsapp(), "_blank");
  };
  return (
    <ButtonPet invert type="link" onClick={onClick}>
      <Typography
        mt={1}
        fontSize={20}
        fontWeight={700}
        sx={{ fontFamily: "Light" }}
      >
        {label}
      </Typography>
    </ButtonPet>
  );
};
