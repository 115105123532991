import React from "react";
import {
  ContainerLinks,
  ContainerLinksFooter,
  ContainerRedes,
  FooterContainer,
} from "./Footer.styled";
import {
  FacebookLogo,
  InstagramLogo,
  TiktokLogo,
  TwitterLogo,
  WhatsappLogo,
  YoutubeLogo,
} from "@phosphor-icons/react";
import { Box, Typography } from "@mui/material";
import { theme } from "../theme";
import { LinkMenuMobile } from "../NavBar/Navbar.styled";
import { routes } from "../../utils/paths";

export const Footer = () => {
  return (
    <FooterContainer>
      <Box>
        <Box>
          <Typography
            variant="h5"
            m={2}
            color={theme.palette.primary.secondary}
          >
            BuscaQR.com
          </Typography>
        </Box>
        <ContainerLinks>
          <LinkMenuMobile to={routes.profile}>Mi perfil</LinkMenuMobile>
          <LinkMenuMobile to={routes.myqrs}>Mis QRs</LinkMenuMobile>
        </ContainerLinks>
      </Box>

      <ContainerRedes id="redesBuscaQR">
        <ContainerLinksFooter to={routes.instagram}>
          <InstagramLogo size={32} />
        </ContainerLinksFooter>
        <ContainerLinksFooter to={routes.facebook}>
          <FacebookLogo size={32} />
        </ContainerLinksFooter>
        <ContainerLinksFooter to={routes.youtube}>
          <YoutubeLogo size={32} />
        </ContainerLinksFooter>
        <ContainerLinksFooter to={routes.twitter}>
          <TwitterLogo size={32} />
        </ContainerLinksFooter>
        <ContainerLinksFooter to={routes.whatsapp}>
          <WhatsappLogo size={32} />
        </ContainerLinksFooter>
        <ContainerLinksFooter to={routes.tikTok}>
          <TiktokLogo size={32} />
        </ContainerLinksFooter>
      </ContainerRedes>
    </FooterContainer>
  );
};
