import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { CaretDown } from "@phosphor-icons/react";
import { TextFieldBOValidate } from "../../../TextFieldBO/TextFieldBOValidate";

export const AccordionRedesSociales = ({
  errors,
  register,
  instagram,
  facebook,
}) => {
  const errorRedes = false;
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<CaretDown size={32} />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography fontWeight={700} color={errorRedes ? "red" : "primary"}>
          Redes sociales
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TextFieldBOValidate
          register={register}
          name="instagram"
          label={"Link de perfil de instagram"}
          errors={errors}
          maxLength={500}
          length={instagram?.length}
          isUrl
        />
        <TextFieldBOValidate
          register={register}
          name="facebook"
          label={"Link de perfil de facebook"}
          errors={errors}
          maxLength={500}
          isUrl
          length={facebook?.length}
        />
      </AccordionDetails>
    </Accordion>
  );
};
