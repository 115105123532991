import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { NavBar } from "./components/NavBar/NavBar";
import { Box, useMediaQuery } from "@mui/material";
import { theme } from "./components/theme";
import { routes } from "./utils/paths";
import { BO } from "./components/BO/BO";
import { Register } from "./components/pages/Register/Register";
import { MyQrs } from "./components/pages/MyQrs/MyQrs";
import { Profile } from "./components/pages/Profile/Profile";
import { QrScannerModal } from "./components/QrScanner/QrScannerModal";
import { Footer } from "./components/Footer/Footer";
import ProfilePet from "./components/pages/Pets/ProfilePet";
import { Home } from "./components/pages/Home/Home";
import { Working } from "./components/pages/Working/Working";
import ProfileObject from "./components/pages/Objects/ProfileObject";
import ProfileStore from "./components/pages/Stores/ProfileStore";

export const RouterApp = () => {
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Router>
      <>
        <NavBar />
        <Box sx={{ backgroundColor: theme.palette.primary.secondary }}>
          <Routes>
            <Route path={"/"} element={<Home />} />

            <Route path={routes?.home + "/pets/:id"} element={<ProfilePet />} />
            <Route
              path={routes?.home + "/admin/:type/:newQR?"}
              element={<BO />}
            />
            <Route
              path={routes?.home + "/objects/:id"}
              element={<ProfileObject />}
            />
            <Route
              path={routes?.home + "/stores/:id"}
              element={<ProfileStore />}
            />
            <Route path={routes?.home + "/register"} element={<Register />} />
            <Route path={routes?.home + "/my-qrs"} element={<MyQrs />} />
            <Route path={routes?.home + "/perfil"} element={<Profile />} />
            <Route
              path={routes?.home + "/como_funciona"}
              element={<Working />}
            />
          </Routes>
        </Box>

        {isSmall && <QrScannerModal fixed />}

        <Footer />
      </>
    </Router>
  );
};
