import { Skeleton } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React from "react";
import { Controller } from "react-hook-form";

export const DatePickerCustom = ({
  control,
  dateFormat,
  maxDate,
  errors,
  name,
  rules,
  label,
  dateField,
  isLoading,
}) => {
  const msgErr = errors?.[name];
  return (
    <>
      {isLoading ? (
        <Skeleton type="rectangular" height={80} />
      ) : (
        <>
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
              <>
                {dateField ? (
                  <>
                    <DatePicker
                      sx={{ width: "100%" }}
                      openTo="year"
                      views={["year", "month", "day"]}
                      selected={field.value}
                      label={label}
                      onChange={(date) => field.onChange(date)}
                      format={dateFormat || ""}
                      value={field?.value || null}
                      maxDate={maxDate || ""}
                    />
                  </>
                ) : (
                  <DatePicker
                    sx={{ width: "100%" }}
                    selected={field.value}
                    label={label}
                    onChange={(date) => field.onChange(date)}
                    format={dateFormat || ""}
                    value={field?.value || null}
                    maxDate={maxDate || ""}
                  />
                )}{" "}
              </>
            )}
          />
          {msgErr && (
            <p className="error" style={{ color: "red", textAlign: "start" }}>
              * {msgErr?.message}
            </p>
          )}
        </>
      )}
    </>
  );
};
