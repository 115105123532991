import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { theme } from "../theme";
import { X } from "@phosphor-icons/react/dist/ssr";
import {
  BoxContainerQR,
  BoxTitle,
  DialogTitleQRModal,
} from "./ModalViewQr.styled";
import { QrCode } from "@phosphor-icons/react";
import { ButtonCustom } from "../Common.styled";

export const ModalViewQr = ({
  name,
  url = "buscaqr.com",
  position = "absolute",
  paramQRBO,
  id,
}) => {
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(false);

  useEffect(() => {
    paramQRBO && openModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramQRBO]);

  const openModal = () => {
    paramQRBO === id && setOpen(true);
  };

  const descargarSVG = () => {
    const contenedorSVG = document.getElementById("contenedor-svg");
    const svgElement = contenedorSVG.querySelector("svg");
    const enlaceDescarga = document.createElement("a");
    const svgBlob = new Blob([svgElement.outerHTML], { type: "image/svg+xml" });

    const url = URL.createObjectURL(svgBlob);
    enlaceDescarga.href = url;
    const nameSVG = id || "myQR";
    enlaceDescarga.download = nameSVG + ".svg";
    enlaceDescarga.click();
  };

  return (
    <Box sx={{ position: position, right: 10, bottom: 10 }}>
      <IconButton
        sx={{
          borderRadius: "50% !important",
          backgroundColor: theme.palette.primary.main,
        }}
        onClick={() => setOpen(true)}
      >
        <QrCode color={theme.palette.primary.secondary} size={32} />
      </IconButton>
      <Dialog
        fullScreen={isSmall}
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitleQRModal>
          <BoxTitle>QR de {name}</BoxTitle>
          <Box onClick={() => setOpen(false)} width={"10%"}>
            <X size={30} />
          </Box>
        </DialogTitleQRModal>
        <DialogContent>
          <Box display={"flex"} justifyContent={"center"}>
            <BoxContainerQR id="contenedor-svg">
              <QRCode
                size={"100%"}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={url}
                viewBox={`0 0 256 256`}
              />
            </BoxContainerQR>
          </Box>
          <Box display={"flex"} justifyContent={"flex-end"}>
            <ButtonCustom onClick={descargarSVG}>Descargar QR</ButtonCustom>
          </Box>
        </DialogContent>
      </Dialog>{" "}
    </Box>
  );
};
