import { Alert, Snackbar } from "@mui/material";

const AppSnackbar = ({ snackbar, setSnackbar }) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ open: false });
  };

  return (
    snackbar.open && (
      <Snackbar
        open={snackbar.open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          color={snackbar.type}
          severity={snackbar.type}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    )
  );
};

export default AppSnackbar;
