import React from "react";
import { useForm } from "react-hook-form";
import { ContainerInfo, ContainerPhoto } from "./Profile.styled";
import { Avatar, Typography } from "@mui/material";
import { theme } from "../../theme";
import { getInitials } from "../../../utils/functions";
import { EmailTextfiled } from "./EmailTextfiled";
import { ValidateEmail } from "../../ValidateEmail/ValidateEmail";

export const FormProfile = ({ userData }) => {
  const { displayName, photoURL, emailVerified, qrs, email } = userData || {};

  const getDefaultValues = () => {
    return { email };
  };
  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
    reset,
  } = useForm({
    defaultValues: getDefaultValues(),
    mode: "onChange",
  });
  const onSubmit = (data) => {
    console.log(data);
  };
  const onError = () => {};

  return (
    <>
      <ContainerPhoto>
        <Avatar
          sx={{
            width: "30vw",
            height: "30vw",
            maxHeight: 300,
            maxWidth: 300,
            fontSize: 100,
            [theme.breakpoints.down("md")]: { fontSize: "10vw" },
          }}
          src={photoURL}
        >
          {displayName && getInitials(displayName)}
        </Avatar>
      </ContainerPhoto>

      <ContainerInfo>
        <Typography mt={2} variant="h5" color={"primary"}>
          Cantidad de QRs activos: {qrs?.length}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <EmailTextfiled
            reset={reset}
            register={register}
            watch={watch}
            errors={errors}
          />
        </form>
        {!emailVerified && <ValidateEmail />}
      </ContainerInfo>
    </>
  );
};
