import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Question, X } from "@phosphor-icons/react";
import React, { useState } from "react";
import { ContainerItemWorking } from "./Working.styled";
import { theme } from "../../theme";
import { ButtonCustom } from "../../Common.styled";

export const ItemWorking = ({ label, text, body, modeButton }) => {
  const [open, setopen] = useState(false);
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const lineas = text
    ?.split("\n")
    ?.map((linea, index) => <p key={index}>{linea}</p>);
  return (
    <>
      {modeButton ? (
        <ButtonCustom invert onClick={() => setopen(true)}>
          {label}
        </ButtonCustom>
      ) : (
        <ContainerItemWorking onClick={() => setopen(true)}>
          <Box minWidth={32}>
            <Question size={32} />
          </Box>

          <Typography ml={2} variant="h6">
            {label}
          </Typography>
        </ContainerItemWorking>
      )}
      {open && (
        <Dialog open={open} fullScreen={isSmall}>
          <DialogTitle display={"flex"} justifyContent={"space-between"}>
            {label}
            <IconButton onClick={() => setopen(false)}>
              <X size={20} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography>{lineas}</Typography>
            <Box>{body}</Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
