import React, { useContext } from "react";
import { Box, Container, Skeleton } from "@mui/material";
import CurrentUserContext from "../../../contexts/CurrentUserContext";
import { theme } from "../../theme";

import { FormProfile } from "./FormProfile";
import { heightNavbar } from "../../../utils/constants";
import { HasntProfile } from "./HasntProfile";

export const Profile = () => {
  const { userData, isLoadingUser } = useContext(CurrentUserContext);

  return (
    <Container
      maxWidth={"sm"}
      sx={{
        minHeight: `calc(100vh - ${heightNavbar})`,
        padding: theme.spacing(5, 0),
      }}
    >
      {userData && !isLoadingUser && <FormProfile userData={userData} />}
      {!userData && !isLoadingUser && <HasntProfile userData={userData} />}
      {isLoadingUser && <ProfileLoading />}
    </Container>
  );
};

const ProfileLoading = () => {
  return (
    <Box alignItems={"center"}>
      <Skeleton variant={"circular"} width={"30vw"} height={"30vw"} />
      <Skeleton height={30} />
      <Skeleton height={30} />
      <Skeleton height={30} />
    </Box>
  );
};
