import styled from "@emotion/styled";
import { Box, Grid } from "@mui/material";
import { theme } from "../../../theme";
import { Link } from "react-router-dom";

export const ContainerItemList = styled(Box)(() => ({
  minHeight: 150,
  display: "flex",
  padding: 20,
}));

export const GridLink = styled(Grid)(() => ({
  alignSelf: "center",
}));

export const ContainerDescription = styled(Box)(() => ({
  textAlign: "start",
  marginLeft: theme.spacing(4),
  overflow: "hidden",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  [theme.breakpoints.down("sm")]: {
    maxWidth: "80%",
    textAlign: "center",
    margin: theme.spacing(0, 4),
  },
}));

export const ContainerItem = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    paddingBottom: theme.spacing(2),
  },
}));

export const ContainerItemLink = styled(Link)(() => ({
  textDecoration: "none",
}));
