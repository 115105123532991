import React from "react";
import { ContainerPet } from "./Pets.styled";
import { Box, CircularProgress } from "@mui/material";

export const SkeletonPet = () => {
  return (
    <ContainerPet disableGutters>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        minHeight={400}
      >
        <CircularProgress size={100} mt={10} />
      </Box>
    </ContainerPet>
  );
};
