import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { CaretDown } from "@phosphor-icons/react";
import React from "react";
import { TextFieldBOValidate } from "../../../TextFieldBO/TextFieldBOValidate";

export const AdditionalData = ({ register, errors, watch, control }) => {
  const { raza, alimento } = watch();

  return (
    <Box mt={2}>
      <Accordion>
        <AccordionSummary
          expandIcon={<CaretDown size={32} />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography fontWeight={700} color={"primary"}>
            Datos adicionales
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextFieldBOValidate
            register={register}
            name="raza"
            label={"Raza"}
            errors={errors}
            maxLength={30}
            length={raza?.length}
          />
          <TextFieldBOValidate
            register={register}
            name="alimento"
            label={"Alimento"}
            errors={errors}
            maxLength={30}
            length={alimento?.length}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
