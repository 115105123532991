import { Grid, TextField } from "@mui/material";

export const EmailTextfiled = ({ register, errors, watch, reset }) => {
  const { email } = watch();

  return (
    <>
      <Grid container justifyContent={"center"} mt={2} mb={2}>
        <Grid item xs={8}>
          <TextField
            id="filled-basic"
            label="Email"
            InputProps={{
              readOnly: true,
            }}
            value={email}
            variant="filled"
            sx={{ width: "100%" }}
          />
        </Grid>
      </Grid>
    </>
  );
};
