import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { NotFound } from "../../404NotFound/NotFound";
import { Pet } from "./Pet";
import CurrentUserContext from "../../../contexts/CurrentUserContext";
import { useGetPetById } from "../../../hooks/Pets/useGetPetById";
import { getStringToBoolean } from "../../../utils/functions";
import { SkeletonPet } from "./SkeletonPet";
import { NewQr } from "../../NewQr/NewQr";

const ProfilePet = () => {
  const { app, db } = useContext(CurrentUserContext);
  let { id } = useParams();
  const { data, isLoading } = useGetPetById(id);
  const notFount = !isLoading && !data;
  const created = getStringToBoolean(data?.created);

  if (isLoading) return <SkeletonPet />;

  if (notFount) return <NotFound />;

  if (created) return <Pet pet={data} />;

  if (!created) return <NewQr id={id} app={app} db={db} />;
};

export default ProfilePet;
