import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { theme } from "../theme";

export const BoxTitle = styled(Box)(() => ({
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  width: "90%",
}));

export const DialogTitleQRModal = styled(Box)(() => ({
  display: "flex",
  fontSize: 30,
  marginTop: 10,
  [theme.breakpoints.down("sm")]: {
    marginTop: 20,
  },
}));

export const BoxContainerQR = styled(Box)(() => ({
  height: "auto",
  width: "70%",
  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
  maxWidth: 400,
}));
