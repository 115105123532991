import { Box, useMediaQuery } from "@mui/material";
import React, { useContext } from "react";
import { BoxCard } from "./Pets.styled";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { theme } from "../../theme";
import { PawPrint } from "@phosphor-icons/react";
import { EditPet } from "./EditPet/EditPet";
import { ModalViewQr } from "../../ModalViewQr/ModalViewQr";
import { getUrlPetQr, isMyQr } from "../../../utils/functions";
import CurrentUserContext from "../../../contexts/CurrentUserContext";
import { useParams } from "react-router-dom";
import { typeObject } from "../../../utils/constants";

export const ImageProfile = ({ pet }) => {
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  let { id } = useParams();
  const { userData } = useContext(CurrentUserContext);
  const owner = isMyQr(pet, userData, typeObject.pets);
  const { name, images } = pet || {};

  return (
    <Box sx={{ position: "relative" }}>
      {images ? (
        <BoxCard sx={{ clipPath: "ellipse(90% 83% at 50% 15%)" }}>
          <LazyLoadImage
            alt={"Imagen de perfil de" + name}
            effect="blur"
            src={isSmall ? images?.medium : images?.big}
            width={"100%"}
            style={{
              objectFit: "cover",
              borderRadius: isSmall ? 0 : 20,
              aspectRatio: "4/3",
            }}
          />
        </BoxCard>
      ) : (
        <Box>
          <PawPrint size={200} />
        </Box>
      )}
      {owner && <EditPet data={pet} />}
      <ModalViewQr name={name} url={getUrlPetQr(id)} />
    </Box>
  );
};
