import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { X } from "@phosphor-icons/react";
import { CameraContainer } from "./CameraContainer";
import { BoxClose, BoxUrlRedirect } from "./QrScanner.styled";
import { ButtonLoading } from "../ButtonLoading";
import { useEffect } from "react";

export const DialogScannerQR = ({
  urlRedirect,
  open,
  setopen,
  onClose,
  seturlRedirect,
  setisLoading,
  isLoading,
}) => {
  const handleRedirect = (urlRedirect) => {
    // Redirige a la página externa utilizando window.location.href
    setisLoading(true);
    window.location.href = urlRedirect;
  };
  useEffect(() => {
    urlRedirect && autoRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlRedirect]);

  const autoRedirect = () => {
    if (urlRedirect?.includes(process.env.REACT_APP_URL)) {
      handleRedirect(urlRedirect);
    }
  };
  return (
    <Dialog open={open} fullWidth fullScreen>
      <DialogTitle display={"flex"} justifyContent={"space-between"}>
        Escaneo BuscaQR
        <IconButton onClick={() => setopen(false)}>
          <X size={20} />
        </IconButton>
      </DialogTitle>
      <Box sx={{ position: "relative" }}>
        <CameraContainer seturlRedirect={seturlRedirect} />
        {urlRedirect && (
          <BoxUrlRedirect>
            <BoxClose>
              <IconButton onClick={onClose}>
                <X size={32} />
              </IconButton>
            </BoxClose>
            <Typography sx={{ overflowWrap: "break-word" }}>
              {urlRedirect}
            </Typography>
            <ButtonLoading
              isLoading={isLoading}
              onClick={() => handleRedirect(urlRedirect)}
            >
              Ir a la pagina
            </ButtonLoading>
          </BoxUrlRedirect>
        )}
      </Box>
    </Dialog>
  );
};
