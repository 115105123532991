import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { CaretDown } from "@phosphor-icons/react";
import { ButtonCustom } from "../../Common.styled";
import { useSnackBars } from "../../../contexts/Snackbar/SnackbarContext";
import { IconMercadoPago } from "../../Icons/IconMercadoPago";
import { theme } from "../../theme";

export const AccordionStore = ({ alias, emailMP }) => {
  const { showSuccessSnackbar } = useSnackBars();

  const copyText = (texto) => {
    navigator.clipboard.writeText(texto);
    showSuccessSnackbar("Alias copiado con exito!");
  };
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<CaretDown size={32} />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography fontWeight={700} color={"primary"}>
          Transferir dinero
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {emailMP && isSmall && (
          <Box mb={2}>
            <Typography textAlign={"left"} fontWeight={700}>
              Desde Mercadopago
            </Typography>
            <ButtonCustom
              invert
              onClick={() => window.open(`mailto:${emailMP}`, "_blank")}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <IconMercadoPago width={30} height={30} />
              <Typography ml={2} fontWeight={700}>
                Transferir dinero
              </Typography>
            </ButtonCustom>
          </Box>
        )}
        {alias && (
          <Box textAlign={"left"}>
            <Typography fontWeight={700}>Desde otros bancos</Typography>
            <Typography>Alias: {alias}</Typography>
            <ButtonCustom
              sx={{ width: "100%" }}
              invert
              onClick={() => copyText(alias)}
            >
              Copiar alias
            </ButtonCustom>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
