import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { theme } from "../theme";

export const ContainerValidateEmailWarning = styled(Box)(() => ({
  display: "flex",
  backgroundColor: theme.palette.common.warningValidateEmail,
  minHeight: 50,
}));

export const ContainerText = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: 450,
  [theme.breakpoints.down("sm")]: {
    maxWidth: 300,
  },
}));
