import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useAllPets } from "../../../hooks/BO/useAllPets";
import {
  dowloadData,
  getStringToBoolean,
  getUrlPetQr,
} from "../../../utils/functions";
import { Link } from "react-router-dom";
import { ButtonCustom } from "../../Common.styled";
import { menuOptionBO } from "../../../utils/constants";

export const TablePets = () => {
  const { data, isLoading: isLoadinTable } = useAllPets();

  return (
    <>
      <ButtonCustom onClick={() => dowloadData(data, menuOptionBO?.pets)}>
        Descargar txt
      </ButtonCustom>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Disponible</TableCell>
              <TableCell>Usuario</TableCell>
              <TableCell>Qr</TableCell>
              <TableCell>Link</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoadinTable ? (
              <div>
                <CircularProgress size={40} />
              </div>
            ) : (
              data?.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>
                    {getStringToBoolean(item?.created?.toString())
                      ? "No"
                      : "Si"}
                  </TableCell>
                  <TableCell>{item.email}</TableCell>
                  {/* <TableCell>
                    <ModalViewQr
                      paramQRBO={newQR}
                      name={item?.name}
                      position={"relative"}
                      url={getUrlPetQr(item.id)}
                      id={item?.id}
                    />
                  </TableCell> */}
                  <TableCell>
                    <Link to={getUrlPetQr(item.id)}>Ir a la pagina</Link>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
