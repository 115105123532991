import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { theme } from "../../theme";

export const ContainerItemWorking = styled(Box)(() => ({
  display: "flex",
  padding: 10,
  border: `1px solid ${theme.palette.common.borderBlueLight}`,
  borderRadius: 10,
  marginTop: 30,
  cursor: "pointer",
}));

export const ContainerWorking = styled(Box)(() => ({
  display: "grid",
  padding: theme.spacing(0, 3, 3, 3),
}));
