import React, { useState } from "react";
import { ButtonCustom } from "../Common.styled";
import { Typography } from "@mui/material";
import { Scan } from "@phosphor-icons/react";
import { DialogScannerQR } from "./DialogScannerQR";
import { BarScanner } from "./BarScanner";

export const QrScannerModal = ({ fixed = false }) => {
  const [open, setopen] = useState(false);
  const [urlRedirect, seturlRedirect] = useState("");
  const [isLoading, setisLoading] = useState(false);

  const onClose = () => {
    seturlRedirect("");
    setisLoading(false);
  };

  return (
    <>
      {fixed ? (
        <BarScanner onClick={() => setopen(true)} />
      ) : (
        <ButtonCustom invert marginTop={"8px"} onClick={() => setopen(true)}>
          <Scan size={22} />
          <Typography ml={1} fontWeight={700}>
            Escanear QR
          </Typography>
        </ButtonCustom>
      )}
      {open && (
        <DialogScannerQR
          urlRedirect={urlRedirect}
          open={open}
          setopen={setopen}
          onClose={onClose}
          seturlRedirect={seturlRedirect}
          setisLoading={setisLoading}
          isLoading={isLoading}
        />
      )}
    </>
  );
};
