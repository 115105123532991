import React, { useState } from "react";
import { ButtonCustom, LinkButton } from "../../Common.styled";
import {
  Dialog,
  DialogContent,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { PaperHasntProfile } from "./Profile.styled";
import { switchModalLoginConstant } from "../../../utils/constants";
import { SwitchModalLogin } from "../../SwitchModalLogin.js/SwitchModalLogin";
import { theme } from "../../theme";

export const HasntProfile = () => {
  const [open, setopen] = useState(false);
  const [option, setOption] = useState();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <PaperHasntProfile>
      <Typography variant="h5" mb={3}>
        ¿Todavia no tienes perfil?
      </Typography>
      <ButtonCustom
        marginTop={"0px"}
        onClick={() => {
          setopen(true);
          setOption(switchModalLoginConstant?.register);
        }}
      >
        Registrarse
      </ButtonCustom>

      <LinkButton
        mt={2}
        onClick={() => {
          setopen(true);
          setOption(switchModalLoginConstant?.login);
        }}
      >
        Ya tengo cuenta
      </LinkButton>

      {open && (
        <Dialog open={true} fullWidth fullScreen={isSmall}>
          <DialogContent>
            <SwitchModalLogin
              option={option}
              closeActive={true}
              onClose={() => setopen(false)}
              setOption={setOption}
            />
          </DialogContent>
        </Dialog>
      )}
    </PaperHasntProfile>
  );
};
