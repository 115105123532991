import React, { useContext } from "react";
import {
  BoxCard,
  ContainerDescription,
  ContainerPet,
  ContainerTitle,
} from "../Pets/Pets.styled";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useParams } from "react-router";
import CurrentUserContext from "../../../contexts/CurrentUserContext";
import { theme } from "../../theme";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Image } from "@phosphor-icons/react/dist/ssr";
import { ButtonWhatsapp } from "../../ButtonWhatsapp/ButtonWhatsapp";
import { ButtonCustom } from "../../Common.styled";
import { Envelope, Phone } from "@phosphor-icons/react";
import { getUrlObjectsQr, isMyQr } from "../../../utils/functions";
import { typeObject } from "../../../utils/constants";
import { ModalViewQr } from "../../ModalViewQr/ModalViewQr";
import { EditObject } from "./EditObject/EditObject";

export const Object = ({ data }) => {
  let { id } = useParams();
  const { userData } = useContext(CurrentUserContext);

  const { title, cel, images, description, email, tel } = data || {};
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const owner = isMyQr(data, userData, typeObject.objects);

  return (
    <ContainerPet disableGutters maxWidth={"sm"}>
      <Box>
        <Box sx={{ position: "relative" }}>
          {images ? (
            <BoxCard>
              <LazyLoadImage
                alt={"Imagen de perfil de" + title}
                effect="blur"
                src={isSmall ? images?.medium : images?.big}
                width={"100%"}
                style={{
                  objectFit: "cover",
                  borderRadius: isSmall ? 0 : 20,
                  aspectRatio: "4/3",
                }}
              />
            </BoxCard>
          ) : (
            <Box>
              <Image size={200} />
            </Box>
          )}
          {owner && <EditObject data={data} />}
          <ModalViewQr name={title} url={getUrlObjectsQr(id)} />
        </Box>
        <ContainerTitle> {title}</ContainerTitle>

        <ContainerDescription> {description}</ContainerDescription>
        <Typography fontWeight={700} fontSize={20} sx={{ marginBottom: 2 }}>
          Contactame con un clic
        </Typography>
        {cel && (
          <Box display={"flex"} justifyContent={"center"}>
            <ButtonWhatsapp
              text={"Hola! escanee el QR de " + data?.title}
              cel={cel}
            />
          </Box>
        )}
        {tel && (
          <Box display={"flex"} justifyContent={"center"}>
            <ButtonCustom
              onClick={() => window.open(`tel:+549${tel}`, "_blank")}
            >
              <Phone size={20} />
              <Typography ml={2} fontWeight={700}>
                {tel}
              </Typography>
            </ButtonCustom>
          </Box>
        )}
        {email && (
          <Box display={"flex"} justifyContent={"center"}>
            <ButtonCustom
              onClick={() => window.open(`mailto:${email}`, "_blank")}
              height={"auto"}
              sx={{ height: "auto" }}
            >
              <Envelope size={20} />
              <Typography
                sx={{ overflowWrap: "anywhere", fontSize: 12 }}
                ml={2}
                fontWeight={700}
              >
                {email}
              </Typography>
            </ButtonCustom>
          </Box>
        )}
      </Box>
    </ContainerPet>
  );
};
