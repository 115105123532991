import React, { useContext, useState } from "react";
import CurrentUserContext from "../../../contexts/CurrentUserContext";
import { namesUseQuery } from "../../../utils/constants";
import { useMutation, useQueryClient } from "react-query";
import { ButtonLoading } from "../../ButtonLoading";
import { ObjectsService } from "../../../services/ObjectsService";
import { TableObjets } from "./TableObjects";
import { ContainerTitle } from "../PetsBO/PetsBO.styled";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "../../../utils/paths";
import { ButtonCustom } from "../../Common.styled";
import { ModalViewQr } from "../../ModalViewQr/ModalViewQr";
import { getUrlObjectsQr } from "../../../utils/functions";

export const ObjetsBO = () => {
  const { userData } = useContext(CurrentUserContext);
  let { newQR } = useParams();

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [viewAll, setviewAll] = useState(false);

  const mutateGenerateQR = async () => {
    const response = await ObjectsService.generateQR(userData?.accessToken);
    return response?.data?.id;
  };

  const { mutate, isLoading } = useMutation(mutateGenerateQR, {
    onSuccess: (id) => {
      alert("Se genero un QR con el siguiente ID: " + id);
      navigate(routes.adminObjects + "/" + id);
      queryClient.invalidateQueries(namesUseQuery?.ALL_PETS);
    },
    onError: (err) => {
      console.error(err);
    },
  });

  return (
    <div>
      <ContainerTitle>
        <h1>Objetos</h1>

        <ButtonLoading
          isLoading={isLoading}
          onClick={() => mutate(userData?.accessToken)}
          sx={{ height: 40 }}
        >
          Generar Nuevo objeto
        </ButtonLoading>
        {newQR && (
          <ModalViewQr
            paramQRBO={newQR}
            name={newQR}
            position={"relative"}
            url={getUrlObjectsQr(newQR)}
            id={newQR}
          />
        )}
      </ContainerTitle>
      <ButtonCustom onClick={() => setviewAll(true)}>
        Mostrar todos
      </ButtonCustom>

      {viewAll && <TableObjets />}
    </div>
  );
};
