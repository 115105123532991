import styled from "@emotion/styled";
import { Paper } from "@mui/material";
import { theme } from "../../theme";

export const PapperHome = styled(Paper)(() => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  width: "80%",

  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
    width: "85%",
    margin: theme.spacing(1, 0),
  },
}));

export const PapperHomeVersionTwo = styled(PapperHome)(() => ({
  marginTop: theme.spacing(2),
}));
