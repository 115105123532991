import React from "react";
import { ButtonCustom } from "./Common.styled";
import { CircularProgress } from "@mui/material";
import { theme } from "./theme";

export const ButtonLoading = ({
  children,
  isLoading,
  onClick,
  invert,
  style,
}) => {
  return (
    <ButtonCustom
      invert={invert}
      sx={{ ...style }}
      loadingIndicator={
        <CircularProgress
          sx={{ color: theme.palette.primary.main }}
          size={20}
        />
      }
      onClick={onClick && onClick}
      loading={isLoading}
      type="submit"
    >
      {children}
    </ButtonCustom>
  );
};
