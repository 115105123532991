import React from "react";
import { Controller } from "react-hook-form";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

export const InputCelNumber = ({ name, control, rules }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <>
          <PhoneInput
            international
            defaultCountry="AR"
            value={value}
            maxLength={20}
            onChange={(newValue) => {
              onChange(newValue || ""); // Maneja el valor vacío
            }}
            style={{
              height: 60,
              fontSize: 24,
            }}
          />
        </>
      )}
    />
  );
};
