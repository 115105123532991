import React, { useContext, useState } from "react";
import { TextFieldBOValidate } from "../TextFieldBO/TextFieldBOValidate";
import { useForm } from "react-hook-form";
import { sendPasswordResetEmail } from "@firebase/auth";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import { ButtonLoading } from "../ButtonLoading";
import { Box } from "@mui/material";
import { useSnackBars } from "../../contexts/Snackbar/SnackbarContext";

export const FormResetPassword = () => {
  const [isLoading, setisLoading] = useState(false);
  const [send, setSend] = useState(false);
  const { showErrorSnackbar } = useSnackBars();

  const { auth } = useContext(CurrentUserContext);

  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
  } = useForm({
    mode: "onBlur",
  });

  const { email, email2 } = watch();

  const onSubmit = async (data, e) => {
    resetPass();
  };
  const onError = (errors, e) => showErrorSnackbar(errors);

  const isValidate = (value) => {
    if (email === email2) return true;
    return "Los emails no coinciden";
  };

  const resetPass = () => {
    setisLoading(true);
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setSend(true);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        showErrorSnackbar(errorCode + " " + errorMessage);
      })
      .finally(() => setisLoading(false));
  };

  return !send ? (
    <div>
      Se enviara un mail a la direccion donde podras cambiar la contraseña. Si
      no lo recibe, por favor revise la casilla de "spam"
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <TextFieldBOValidate
          register={register}
          name="email"
          label={"Email"}
          errors={errors}
          isRequired
          isEmail
        />
        <TextFieldBOValidate
          register={register}
          name="email2"
          label={"Repetir el email"}
          errors={errors}
          isRequired
          extravalidations={isValidate}
          isEmail
          disabledPaste
        />
        <ButtonLoading isLoading={isLoading} type="submit">
          Enviar mail de recuperación
        </ButtonLoading>
      </form>
    </div>
  ) : (
    <Box>
      Se envio el mail de recuperacion a <b>{email}</b> , en el mismo podras
      generar una nueva clave.
    </Box>
  );
};
